import { useSelector } from 'react-redux';
import { getLocalizationValue } from 'src/utils/common';
import { LOCALIZATION, MESSAGES, TEXT } from 'src/utils/constant';

function useLanguage() {
  const lang = useSelector((state) => state.app.lang) || LOCALIZATION.ru_RU;

  return {
    lang,
    localizeText: TEXT[lang],
    localizeMessage: MESSAGES[lang],
    getLocalizationValue: (object, field) =>
      getLocalizationValue(object, lang, field) || getLocalizationValue(object, LOCALIZATION.ru_RU, field),
    getValueByLang: (object, field) => object[field]?.[lang] || object[field]?.[LOCALIZATION.ru_RU] || '',
  };
}

export default useLanguage;
