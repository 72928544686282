// ** React Imports
import { useState, useEffect, forwardRef, useCallback, Fragment } from 'react';

// ** MUI Imports
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel from '@mui/material/FormControlLabel';

// ** Third Party Imports
import { useForm, Controller } from 'react-hook-form';

// ** Icon Imports
import Icon from 'src/@core/components/icon';

// ** Styled Components
import { useLanguage } from 'src/hooks';
import moment from 'moment-timezone';
import { displayTimeByFormat } from 'src/utils/time';
import { STANDARD_DATE_TIME_FORMAT } from 'src/utils/constant';

const capitalize = (string) => string && string[0].toUpperCase() + string.slice(1);

const defaultState = {
  url: '',
  title: '',
  guests: [],
  allDay: true,
  description: '',
  email: '',
  telephone: '',
  endDate: new Date(),
  calendar: 'program',
  startDate: new Date(),
};

const AddEventSidebar = (props) => {
  // ** Props
  const {
    store,
    dispatch,
    addEvent,
    updateEvent,
    drawerWidth,
    calendarApi,
    deleteEvent,
    handleSelectEvent,
    addEventSidebarOpen,
    handleAddEventSidebarToggle,
  } = props;

  const { localizeText } = useLanguage();

  // ** States
  const [values, setValues] = useState(defaultState);

  const {
    control,
    setValue,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { title: '' } });

  const handleSidebarClose = async () => {
    setValues(defaultState);
    clearErrors();
    dispatch(handleSelectEvent(null));
    handleAddEventSidebarToggle();
  };

  const onSubmit = (data) => {
    const modifiedEvent = {
      url: values.url,
      display: 'block',
      title: data.title,
      end: values.endDate,
      allDay: values.allDay,
      start: values.startDate,
      extendedProps: {
        calendar: capitalize(values.calendar),
        guests: values.guests && values.guests.length ? values.guests : undefined,
        description: values.description.length ? values.description : undefined,
      },
    };
    if (store.selectedEvent === null || (store.selectedEvent !== null && !store.selectedEvent.title.length)) {
      dispatch(addEvent(modifiedEvent));
    } else {
      dispatch(updateEvent({ id: store.selectedEvent.id, ...modifiedEvent }));
    }
    calendarApi.refetchEvents();
    handleSidebarClose();
  };

  const handleDeleteEvent = () => {
    if (store.selectedEvent) {
      dispatch(deleteEvent(store.selectedEvent.id));
    }

    // calendarApi.getEventById(store.selectedEvent.id).remove()
    handleSidebarClose();
  };

  const handleStartDate = (date) => {
    if (date > values.endDate) {
      setValues({ ...values, startDate: new Date(date), endDate: new Date(date) });
    }
  };

  const resetToStoredValues = useCallback(() => {
    if (store.selectedEvent !== null) {
      const event = store.selectedEvent;
      setValue('title', event.title || '');
      setValues({
        url: event.url || '',
        title: event.title || '',
        email: event.extendedProps.email || '',
        telephone: event.extendedProps.telephone || '',
        allDay: event.allDay,
        guests: event.extendedProps.guests || [],
        description: event.extendedProps.description || '',
        calendar: event.extendedProps.calendar || 'Business',
        endDate:
          event.extendedProps.end !== null
            ? displayTimeByFormat(event.extendedProps.end, STANDARD_DATE_TIME_FORMAT)
            : displayTimeByFormat(event.extendedProps.start, STANDARD_DATE_TIME_FORMAT),
        startDate:
          event.extendedProps.start !== null
            ? displayTimeByFormat(event.extendedProps.start, STANDARD_DATE_TIME_FORMAT)
            : displayTimeByFormat(moment(), STANDARD_DATE_TIME_FORMAT),
      });
    }
  }, [setValue, store.selectedEvent]);

  const resetToEmptyValues = useCallback(() => {
    setValue('title', '');
    setValues(defaultState);
  }, [setValue]);
  useEffect(() => {
    if (store.selectedEvent !== null) {
      resetToStoredValues();
    } else {
      resetToEmptyValues();
    }
  }, [addEventSidebarOpen, resetToStoredValues, resetToEmptyValues, store.selectedEvent]);

  const PickersComponent = forwardRef(({ ...props }, ref) => {
    return (
      <TextField
        inputRef={ref}
        fullWidth
        {...props}
        label={props.label || ''}
        sx={{ width: '100%' }}
        error={props.error}
      />
    );
  });

  const RenderSidebarFooter = () => {
    if (store.selectedEvent === null || (store.selectedEvent !== null && !store.selectedEvent.title.length)) {
      return (
        <Fragment>
          <Button size="large" type="submit" variant="contained" sx={{ mr: 4 }}>
            Add
          </Button>
          <Button size="large" variant="outlined" color="secondary" onClick={resetToEmptyValues}>
            Reset
          </Button>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <Button size="large" type="submit" variant="contained" sx={{ mr: 4 }}>
            Update
          </Button>
          <Button size="large" variant="outlined" color="secondary" onClick={resetToStoredValues}>
            Reset
          </Button>
        </Fragment>
      );
    }
  };

  return (
    <Drawer
      anchor="right"
      open={addEventSidebarOpen}
      onClose={handleSidebarClose}
      ModalProps={{ keepMounted: true }}
      sx={{ '& .MuiDrawer-paper': { width: ['100%', drawerWidth] } }}
    >
      <Box
        className="sidebar-header"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          backgroundColor: 'rgb(244, 245, 250)',
          p: (theme) => theme.spacing(3, 3.255, 3, 5.255),
        }}
      >
        <Typography variant="h6">{localizeText.DETAIL}</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton size="small" onClick={handleSidebarClose} sx={{ color: 'text.primary' }}>
            <Icon icon="mdi:close" fontSize={20} />
          </IconButton>
        </Box>
      </Box>
      <Box className="sidebar-body" sx={{ p: (theme) => theme.spacing(5, 6) }}>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <FormControl fullWidth sx={{ mb: 6 }}>
            <Controller
              name="title"
              control={control}
              rules={{ required: true }}
              render={({ field: { value, onChange } }) => (
                <TextField
                  multiline
                  disabled
                  label={localizeText.NAME_COMPANY}
                  value={value}
                  onChange={onChange}
                  error={Boolean(errors.title)}
                />
              )}
            />
            {errors.title && (
              <FormHelperText sx={{ color: 'error.main' }} id="event-title-error">
                This field is required
              </FormHelperText>
            )}
          </FormControl>
          <FormControl fullWidth sx={{ mb: 6 }} disabled>
            <InputLabel id="event-calendar">{localizeText.CALENDAR}</InputLabel>
            <Select
              label={localizeText.TYPE}
              value={values.calendar}
              labelId="event-calendar"
              onChange={(e) => setValues({ ...values, calendar: e.target.value })}
            >
              <MenuItem value="program">{localizeText.PROGRAM}</MenuItem>
              <MenuItem value="meeting">{localizeText.MEETING}</MenuItem>
            </Select>
          </FormControl>
          <Box sx={{ mb: 6 }}>
            <TextField
              InputLabelProps={{ shrink: true }}
              fullWidth
              label={localizeText.START}
              value={values.startDate}
              disabled
            />
          </Box>
          <Box sx={{ mb: 6 }}>
            <TextField
              InputLabelProps={{ shrink: true }}
              fullWidth
              label={localizeText.END}
              value={values.endDate}
              disabled
            />
          </Box>
          <Box sx={{ mb: 6 }}>
            <TextField
              multiline
              InputLabelProps={{ shrink: true }}
              fullWidth
              label={localizeText.EMAIL}
              value={values.email}
              disabled
            />
          </Box>
          <Box sx={{ mb: 6 }}>
            <TextField
              InputLabelProps={{ shrink: true }}
              fullWidth
              label={localizeText.TELEPHONE_VIZIT}
              value={values.telephone}
              disabled
            />
          </Box>
        </form>
      </Box>
    </Drawer>
  );
};

export default AddEventSidebar;
