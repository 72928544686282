// ** React Imports
import { useState, Fragment } from 'react';

// ** Next Import
// import { useRouter } from 'next/router'

// ** MUI Imports
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

// ** Icon Imports
import Icon from 'src/@core/components/icon';

// ** Context
import { useAuth } from 'src/hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { logout } from 'src/store/actions/auth';
import { routes } from 'src/utils/routes';
import { MyAvatar } from 'src/components/avatar';
import { getFullName } from 'src/utils/common';
import { Skeleton } from '@mui/material';
import { LOCALIZATION, TEXT } from 'src/utils/constant';
import { ProfileSummary } from 'src/components';

// ** Styled Components
const BadgeContentSpan = styled('span')(({ theme }) => ({
  width: 8,
  height: 8,
  borderRadius: '50%',
  backgroundColor: theme.palette.success.main,
  boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
}));

const UserDropdown = (props) => {
  // ** Props
  const { settings } = props;

  // ** States
  const [anchorEl, setAnchorEl] = useState(null);

  const user = useSelector((state) => state.auth.user);
  const lang = useSelector((state) => state.app.lang) || LOCALIZATION.ru_RU;
  const isLoadingAvatar = useSelector((state) => state.auth.isLoadingAvatar);

  // ** Hooks
  const router = useNavigate();

  // ** Vars
  const { direction } = settings;

  const handleDropdownOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = (url) => {
    if (url) {
      router(url);
    }
    setAnchorEl(null);
  };

  const styles = {
    py: 2,
    px: 4,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    color: 'text.primary',
    textDecoration: 'none',
    '& svg': {
      mr: 2,
      fontSize: '1.375rem',
      color: 'text.primary',
    },
  };

  const handleLogout = () => {
    logout();
    handleDropdownClose();
  };

  return (
    <Fragment>
      <Badge
        overlap="circular"
        onClick={handleDropdownOpen}
        sx={{ cursor: 'pointer' }}
        // badgeContent={<BadgeContentSpan />}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <MyAvatar sx={{ width: 40, height: 40 }} hideUploadAvatar={true} hideOpenAvatar={true} />
      </Badge>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleDropdownClose()}
        sx={{ '& .MuiMenu-paper': { maxWidth: 350, mt: 4 } }}
        anchorOrigin={{ vertical: 'bottom', horizontal: direction === 'ltr' ? 'right' : 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: direction === 'ltr' ? 'right' : 'left' }}
      >
        <Box sx={{ pt: 2, pb: 3, px: 4 }}>
          <ProfileSummary />
        </Box>
        <Divider sx={{ mt: '0 !important' }} />
        {/* <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose('/pages/user-profile/profile')}>
          <Box sx={styles}>
            <Icon icon="mdi:account-outline" />
            Profile
          </Box>
        </MenuItem> */}
        {/* <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose('/apps/email')}>
          <Box sx={styles}>
            <Icon icon="mdi:email-outline" />
            Inbox
          </Box>
        </MenuItem>
        <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose('/apps/chat')}>
          <Box sx={styles}>
            <Icon icon="mdi:message-outline" />
            Chat
          </Box>
        </MenuItem> */}
        {/* <Divider /> */}
        {/* {!user.isStaff && (
          <MenuItem
            sx={{ py: 2.5, '& svg': { mr: 2, fontSize: '1.375rem', color: 'text.primary' } }}
            onClick={() => handleDropdownClose(routes.profile.path)}
          >
            <Icon icon="mdi:account-outline" />
            {TEXT[lang].PROFILE}
          </MenuItem>
        )} */}
        {/* <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose('/pages/pricing')}>
          <Box sx={styles}>
            <Icon icon="mdi:currency-usd" />
            Pricing
          </Box>
        </MenuItem>
        <MenuItem sx={{ p: 0 }} onClick={() => handleDropdownClose('/pages/faq')}>
          <Box sx={styles}>
            <Icon icon="mdi:help-circle-outline" />
            FAQ
          </Box>
        </MenuItem> */}
        {/* <Divider /> */}
        <MenuItem
          onClick={handleLogout}
          sx={{ py: 2.5, '& svg': { mr: 2, fontSize: '1.375rem', color: 'text.primary' } }}
        >
          <Icon icon="mdi:logout-variant" />
          {TEXT[lang].LOGOUT_BUTTON}
        </MenuItem>
      </Menu>
    </Fragment>
  );
};

export default UserDropdown;
