import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LanguageSelect from 'src/components/language-select';
import UserIcon from 'src/icons/user';
import { routes } from 'src/utils/routes';
import { ExitAppIcon, SecurityIcon } from '../../../../icons';
import { logout } from '../../../../store/actions/auth';
import { LOCALIZATION, TEXT } from '../../../../utils/constant';

function LeftSettingsMenu() {
  const navigate = useNavigate();
  const lang = useSelector((state) => state.app.lang) || LOCALIZATION.ru_RU;

  const handleLogout = () => logout();

  return (
    <List component="div">
      <LanguageSelect />
      <ListItem
        button
        onClick={() => navigate(routes.faqs.path)}
        sx={{
          borderRadius: '4px',
          '&:hover': {
            background: 'rgb(240 237 237 / 22%)',
          },
        }}
      >
        <ListItemIcon sx={{ minWidth: '32px !important' }}>
          <SecurityIcon />
        </ListItemIcon>
        <ListItemText primary={TEXT[lang].SUPPORT_BUTTON} primaryTypographyProps={{ sx: { color: 'white' } }} />
      </ListItem>
      <ListItem
        button
        onClick={handleLogout}
        sx={{
          borderRadius: '4px',
          '&:hover': {
            background: 'rgb(240 237 237 / 22%)',
          },
        }}
      >
        <ListItemIcon sx={{ minWidth: '32px !important' }}>
          <ExitAppIcon color="#FFF" />
        </ListItemIcon>
        <ListItemText primary={TEXT[lang].LOGOUT_BUTTON} primaryTypographyProps={{ sx: { color: 'white' } }} />
      </ListItem>
    </List>
  );
}

export default LeftSettingsMenu;
