import { MenuItem, Select } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { routes } from 'src/utils/routes';

function SelectCurrentProject({ basePath }) {
  const { projectUuid } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const user = useSelector((state) => state.auth.user);
  const projects = useSelector((state) => state.app.projects);
  const lang = useSelector((state) => state.app.lang);

  const isProjectDetailPath = basePath === routes.projectDetail.path;
  const joinedProjects = projects.filter((project) => user?.projectUuids?.includes(project.uuid));

  const handleChangeCurrentProject = (e) => {
    const value = e.target.value;
    navigate(location.pathname.replace(projectUuid, value));
  };

  return (
    <Select value={projectUuid} size="small" onChange={handleChangeCurrentProject}>
      {(isProjectDetailPath || joinedProjects.find((p) => p.uuid === projectUuid) ? projects : joinedProjects).map(
        (project, index) => (
          <MenuItem value={project.uuid} key={index}>
            {project.localization[lang]?.fullName}
          </MenuItem>
        )
      )}
    </Select>
  );
}

export default SelectCurrentProject;
