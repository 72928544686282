import { Tooltip } from '@mui/material';
import React from 'react';

function TextFieldLabel({ label, placement = 'bottom' }) {
  const isLong = label?.length > 50;

  const component = <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%' }}>{label}</span>;
  return isLong ? (
    <Tooltip disableFocusListener placement={placement} title={label}>
      {component}
    </Tooltip>
  ) : (
    component
  );
}

export default TextFieldLabel;
