import { ChevronLeft } from '@mui/icons-material';
import GroupsIcon from '@mui/icons-material/Groups';
import { Box, CircularProgress, Grid, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import NavigateCard from 'src/components/navigate-card';
import { useAllowedNetworking } from 'src/hooks';
import { routes } from 'src/utils/routes';
import { ConfirmModal, MainLayout, UserProject } from '../../components';
import Meetings from '../../components/meetings';
import { ProgramDetailModal } from '../../components/program-detail-modal';
import CalendarFillIcon from '../../icons/calendar-fill';
import ChatDotIcon from '../../icons/chat-dot';
import PersonalLineIcon from '../../icons/personal-line';
import { userAPIs } from '../../services';
import { getProjectsList, setPageState } from '../../store/actions/app';
import { LOCALIZATION, MESSAGES, TEXT } from '../../utils/constant';
import { createErrorNotification } from '../../utils/notifications';
import VizitsSearchPage from '../vizits-search';
import BusinessCardPage from './components/business-card';
import CalendarVizitsPage from './components/calendar';
import MeetingsPage from './components/meetings';
import classes from './project.module.sass';

const VizitsPage = () => {
  const navigate = useNavigate();
  const lang = useSelector((state) => state.app.lang) || LOCALIZATION.ru_RU;

  const TABS = {
    BUSINESS_CARD: {
      key: 'business-card',
    },
    CALENDAR: {
      key: 'calendar',
    },
    CHAT: {
      key: 'search',
    },
    MEETINGS: {
      key: 'meetings',
    },
  };

  const { projectUuid: id } = useParams();
  const location = useLocation();
  const user = useSelector((state) => state.auth.user);
  const [isLoading, setIsLoading] = useState(true);
  const [project, setProject] = useState(null);
  const [vizits, setVizits] = useState(null);
  const [membersVizits, setMembersVizits] = useState(null);
  const [tab, setTab] = useState(null);
  const { isAllowedNetworking } = useAllowedNetworking({ dependencies: [location] });
  const [isCheckingVizit, setIsCheckingVizit] = useState(false);

  const handleReturnProject = () => {
    navigate(routes.projectDetail.path.replace(':projectUuid', id));
  };

  const handleSetTab = (tab) => {
    if (!project) return;
    if (tab) {
      navigate(`/projects/vizits/${project.uuid}?tab=${tab}`);
    } else {
      navigate(`/projects/vizits/${project.uuid}`);
    }
  };

  const fetchVizit = async () => {
    try {
      const vizitsRes = await userAPIs.getUserVizitsProject(id);
      setVizits(vizitsRes.message);
    } catch (e) {
      console.error(e);
    }
  };

  const fetchUserVizitsData = async () => {
    try {
      const membersVizitsRes = await userAPIs.getMembersVizitsProject(id);
      setMembersVizits(membersVizitsRes.message);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const projects = await getProjectsList(queryString.stringify({ uuid: id }), false);
        if (projects.length > 0) {
          const _project = projects[0];
          setProject(_project);
        }
        await Promise.all([fetchUserVizitsData()]);
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    const isJoinedProject = user?.projectUuids?.includes(id);
    if (!isJoinedProject) {
      navigate(routes.projectDetail.path.replace(':projectUuid', id));
    }
  }, [user]);

  useEffect(() => {
    if (location) {
      const query = queryString.parse(location.search);
      setTab(query.tab);
      fetchVizit();
    }
  }, [location]);

  const images =
    project?.photos?.main_image?.map((photo) => {
      return {
        original: photo,
        thumbnail: photo,
      };
    }) || [];

  const checkUserVizit = async () => {
    try {
      setIsCheckingVizit(true);
      await userAPIs.getUserVizitsProject(id);
      return true;
    } catch (e) {
      return false;
    } finally {
      setIsCheckingVizit(false);
    }
  };

  const handleGotoChat = async (tabKey) => {
    if (!(await checkUserVizit(id))) {
      createErrorNotification(MESSAGES[lang].DO_NOT_HAVE_USER_VIZIT);
      setTimeout(() => handleSetTab(TABS.BUSINESS_CARD.key), 200);
      return;
    }
    handleSetTab(tabKey);
  };

  const GridItemCard = ({ children }) => (
    <Grid item xs={12} md={6} lg={6} sx={{ height: { xs: 100, md: 120 } }}>
      {children}
    </Grid>
  );

  const renderTabs = () => {
    const isApprovedVizit = vizits && vizits.status === 'approved';
    const disabledChat = !vizits || (!isApprovedVizit && !isAllowedNetworking);

    return Object.values(TABS).map((tab, i) => {
      switch (tab.key) {
        case TABS.BUSINESS_CARD.key:
          return (
            <GridItemCard key={i}>
              <NavigateCard
                label={TEXT[lang].MY_BUSINESS_CARD}
                icon={<PersonalLineIcon />}
                onClick={() => handleSetTab(tab.key)}
              />
            </GridItemCard>
          );
        // case TABS.CALENDAR.key:
        //   return (
        //     <GridItemCard key={i}>
        //       <NavigateCard
        //         label={TEXT[lang].CALENDAR}
        //         icon={<CalendarFillIcon />}
        //         onClick={() => handleSetTab(tab.key)}
        //       />
        //     </GridItemCard>
        //   );
        case TABS.CHAT.key:
          return (
            <GridItemCard key={i}>
              <Tooltip title={disabledChat ? MESSAGES[lang].CREATE_YOUR_BUSINESS_CARD_TO_BUY_A_PACKAGE : ''}>
                <Stack sx={{ height: '100%' }}>
                  <NavigateCard
                    label={TEXT[lang].I_WANT_TO_CHAT}
                    icon={<ChatDotIcon />}
                    disabled={disabledChat}
                    isLoading={isCheckingVizit}
                    onClick={() => (disabledChat ? undefined : handleGotoChat(tab.key))}
                  />
                </Stack>
              </Tooltip>
            </GridItemCard>
          );
        case TABS.MEETINGS.key:
          return (
            <GridItemCard key={i}>
              <NavigateCard label={TEXT[lang].MEETINGS} icon={<GroupsIcon />} onClick={() => handleSetTab(tab.key)} />
            </GridItemCard>
          );
      }
    });
  };

  const renderTabComponent = () => {
    switch (tab) {
      case TABS.BUSINESS_CARD.key:
        return (
          <BusinessCardPage
            isLoading={isLoading}
            projectUuid={id}
            vizits={vizits}
            setVizits={setVizits}
            setTab={handleSetTab}
            membersVizits={membersVizits}
            setMembersVizits={setMembersVizits}
          />
        );
      // case TABS.CALENDAR.key:
      //   return <CalendarVizitsPage projectUuid={id} vizits={vizits} setTab={handleSetTab} />;
      case TABS.MEETINGS.key:
        return <MeetingsPage projectUuid={id} vizits={vizits} setTab={handleSetTab} />;
      case TABS.CHAT.key:
        return <VizitsSearchPage setTab={handleSetTab} />;
    }
  };

  return (
    <Stack gap="2rem" justifyContent="center" className={classes.root}>
      {tab ? (
        renderTabComponent()
      ) : (
        <>
          <div className={classes.header}>
            <IconButton onClick={handleReturnProject}>
              <ChevronLeft />
            </IconButton>
            <h2 className={classes.title}>{TEXT[lang].NETWORKING}:</h2>
          </div>
          <Grid container spacing={8} className={classes.statistic}>
            {renderTabs()}
          </Grid>
          <div className={classes.statisticContent}>
            <div className={classes.headerMobile}>
              <IconButton onClick={handleReturnProject}>
                <ChevronLeft />
              </IconButton>
              <h2 className={classes.title}>{TEXT[lang].NETWORKING}:</h2>
            </div>
            <h2>{TEXT[lang].ABOUT_THE_EXCHANGE}</h2>
            <div className={classes.desc}>
              <p style={{ whiteSpace: 'break-spaces' }}>{TEXT[lang].EXCHANGE_TEXT_EXTRA}</p>
              {/* <p style={{ fontSize: 13, textAlign: 'center' }}>{TEXT[lang].EXCHANGE_TEXT_SMALL}</p> */}
            </div>
            <Grid container className={classes.statisticMobile}>
              {renderTabs()}
            </Grid>
          </div>
        </>
      )}
    </Stack>
  );
};

export default VizitsPage;
