import { Badge as MuiBadge } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledBadge = styled(MuiBadge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  },
  '&.shake': {
    animation: 'shake 1s infinite',
  },
  '@keyframes shake': {
    '25%': {
      transform: 'translateX(6px)',
    },
    '50%': {
      transform: 'translateX(-4px)',
    },
    '75%': {
      transform: 'translateX(2px)',
    },
    '100%': {
      transform: 'translateX(0)',
    },
  },
}));

export const Badge = ({ id, badgeContent, children, style }) => {
  return (
    <StyledBadge id={id} badgeContent={badgeContent} sx={{ ...style }}>
      {children}
    </StyledBadge>
  );
};
