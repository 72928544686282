import { ChevronLeft, CloseOutlined } from '@mui/icons-material';
import FilterListIcon from '@mui/icons-material/FilterList';
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import queryString from 'query-string';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ConfirmAppointmentModal } from '../../components/confirm-appoinment-modal';
import { userAPIs } from '../../services';
import { DEFAULT_UTC_OFFSET, LOCALIZATION, MESSAGES, TEXT } from '../../utils/constant';
import CompanySection from './component/company';
import classes from './vizits-search.module.sass';
import MeetingFilters from './component/Filters';
import moment from 'moment-timezone';
import DatePicker from 'react-datepicker';
import { setMinutes, setHours } from 'date-fns';
import { yupResolver } from '@hookform/resolvers/yup';
import { vizitSearchValidationSchema } from '../../utils/formValidator';
import { timeZoneConverter, timeZone } from '../../utils/time';
import { routes } from 'src/utils/routes';
import { MainLayout, UserProject } from 'src/components';
import { getProjectsList } from 'src/store/actions/app';
import { fetchUserVizit } from 'src/store/apps/chat';
import { dispatch } from 'src/store';
import { InView } from 'react-intersection-observer';
import { useAllowedNetworking } from 'src/hooks';

const VizitsSearchPage = ({ setTab }) => {
  const { projectUuid: id } = useParams();
  const navigate = useNavigate();
  const lang = useSelector((state) => state.app.lang) || LOCALIZATION.ru_RU;
  const userVizit = useSelector((state) => state.chat.userVizit);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isReadyTalk, setIsReadyTalk] = useState(false);
  const [results, setResults] = useState([]);
  const [total, setTotal] = useState(0);
  const [filterTotal, setFilterTotal] = useState(0);
  const [isInit, setIsInit] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [canLoadMore, setCanLoadMore] = useState(true);
  const [query, setQuery] = useState({});
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState({});
  const [project, setProject] = useState(null);

  const { isLoading: isLoadingAllowedNetworking, isAllowedNetworking } = useAllowedNetworking();

  const {
    control,
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(
      () => ({
        companyName: '',
        field: '',
        keyWord: '',
        fullName: '',
        role: '',
      }),
      []
    ),
    resolver: yupResolver(vizitSearchValidationSchema(lang)),
  });

  const onSubmit = async (values) => {
    if (isSubmitting) return;
    try {
      setIsSubmitting(true);
      const since = timeZoneConverter(
        moment(values.since),
        DEFAULT_UTC_OFFSET,
        timeZone(moment(values.since).toDate())
      );
      const till = timeZoneConverter(moment(values.till), DEFAULT_UTC_OFFSET, timeZone(moment(values.till).toDate()));
      var queries = {
        limit: 20,
        offset: 0,
        ...Object.fromEntries(Object.entries(values).filter(([key, value]) => value)),
        ...(values.since ? { since: since.unix() * 1000 } : {}),
        ...(values.till ? { till: till.unix() * 1000 } : {}),
      };
      if (!values.since) delete queries.since;
      if (!values.till) delete queries.till;
      if (isReadyTalk === true) {
        queries = {
          ...queries,
          isReadyTalk,
        };
      }
      setQuery(queries);
      const queryParams = queryString.stringify(queries);
      const res = await userAPIs.searchVizits(id, queryParams);
      const vizits = (res.message || {}).vizits || [];
      setResults(vizits);
      setCanLoadMore(vizits.length === 20);
    } catch (e) {
      console.log(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const fetchData = async () => {
    if (isLoadingMore) return;
    try {
      setIsLoadingMore(true);
      const limit = 10;
      const offset = Math.floor(results.length / limit) * limit;
      const queries = {
        ...query,
        limit,
        offset,
      };
      const queryParams = queryString.stringify(queries);
      const res = await userAPIs.searchVizits(id, queryParams);
      const vizits = (res.message || {}).vizits || [];
      const _total = (res.message || {}).totalCount || 0;
      setCanLoadMore(vizits.length === limit);
      const newResults = results.concat(vizits);
      setResults(newResults);
      setTotal(_total);
      setFilterTotal(_total);

      const projects = await getProjectsList(queryString.stringify({ uuid: id }), false);
      if (projects.length > 0) {
        const _project = projects[0];
        setProject(_project);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoadingMore(false);
      if (isSubmitting) setIsSubmitting(false);
      setIsInit(true);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    dispatch(fetchUserVizit(id));
  }, [id]);

  useEffect(() => {
    if (!isLoadingAllowedNetworking && userVizit && userVizit?.status !== 'approved' && !isAllowedNetworking) {
      navigate(routes.projectDetail.path.replace(':projectUuid', id));
    }
  }, [userVizit, isLoadingAllowedNetworking]);

  const handleReset = () => {
    reset();
    setIsReadyTalk(false);
    handleSubmit(onSubmit)();
  };

  const handleLoadMore = (isVisible) => {
    if (isVisible && canLoadMore && !isSubmitting) {
      fetchData();
    }
  };

  const labelStyle = { fontSize: 17, fontFamily: 'Lato' };
  const inputStyle = { fontSize: 17, fontFamily: 'Lato', width: '100%', borderRadius: '10px' };
  const isLocked = false;

  // const _results =
  //   filters?.since && filters?.till
  //     ? results.map((r) => {
  //         return {
  //           ...r,
  //           members: r.members.map((member) => ({
  //             ...member,
  //             slots: member.slots.filter((slot) => {
  //               return (
  //                 moment.unix(slot.startTime).isBetween(moment.unix(filters.since), moment.unix(filters.till)) &&
  //                 moment.unix(slot.endTime).isBetween(moment.unix(filters.since), moment.unix(filters.till))
  //               );
  //             }),
  //           })),
  //         };
  //       })
  //     : results;

  const _results = query?.isReadyTalk
    ? results.filter((r) => {
      return !(r.members || []).every((member) => (member.slots || []).length === 0);
    })
    : results;

  return (
    <Card
      sx={{
        borderBottomRightRadius: { xs: '0', md: '30px' },
        borderBottomLeftRadius: { xs: '0', md: '30px' },
      }}
    >
      <CardContent className={classes.root}>
        <Stack direction="row" alignItems="center" flexWrap="wrap" sx={{ marginBottom: '18px' }}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2 }}
            onClick={() => setTab(null)}
          >
            <ChevronLeft />
          </IconButton>
          <Typography variant="h5" noWrap component="div" sx={{ mr: 2, lineHeight: '34px' }}>
            {TEXT[lang].COMPANIES_DIRECTORY}
          </Typography>
          {isInit && (
            <Typography variant="h10" noWrap component="div" sx={{ mt: '4px', lineHeight: '20px' }}>
              {TEXT[lang].TOTAL} {total || 0} {TEXT[lang].COMPANIES.toLowerCase()}
            </Typography>
          )}
        </Stack>
        <form className={classes.form} noValidate onSubmit={handleSubmit(onSubmit)}>
          <Stack direction="row" gap="16px" className={classes.filter}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4} algin="left">
                <TextField
                  margin="normal"
                  InputLabelProps={{
                    sx: {
                      '& .MuiInputLabel-asterisk': {
                        color: 'red',
                      },
                    },
                    style: labelStyle,
                  }}
                  InputProps={{ style: inputStyle }}
                  size="small"
                  sx={{ width: '100%', marginTop: 0 }}
                  {...register('companyName')}
                  placeholder={TEXT[lang].COMPANY_NAME}
                  error={errors.companyName ? true : false}
                  helperText={errors.companyName?.message}
                  disabled={isLocked}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} algin="left">
                <TextField
                  margin="normal"
                  InputLabelProps={{
                    sx: {
                      '& .MuiInputLabel-asterisk': {
                        color: 'red',
                      },
                    },
                    style: labelStyle,
                  }}
                  InputProps={{ style: inputStyle }}
                  size="small"
                  sx={{ width: '100%', marginTop: 0 }}
                  {...register('field')}
                  placeholder={TEXT[lang].INDUSTRY}
                  error={errors.field ? true : false}
                  helperText={errors.field?.message}
                  disabled={isLocked}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} algin="left">
                <TextField
                  margin="normal"
                  InputLabelProps={{
                    sx: {
                      '& .MuiInputLabel-asterisk': {
                        color: 'red',
                      },
                    },
                    style: labelStyle,
                  }}
                  InputProps={{ style: inputStyle }}
                  size="small"
                  sx={{ width: '100%', marginTop: 0 }}
                  {...register('keyWord')}
                  placeholder={TEXT[lang].KEYWORDS}
                  error={errors.keyWord ? true : false}
                  helperText={errors.keyWord?.message}
                  disabled={isLocked}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} algin="left">
                <TextField
                  margin="normal"
                  InputLabelProps={{
                    sx: {
                      '& .MuiInputLabel-asterisk': {
                        color: 'red',
                      },
                    },
                    style: labelStyle,
                  }}
                  InputProps={{ style: inputStyle }}
                  size="small"
                  sx={{ width: '100%', marginTop: 0 }}
                  {...register('fullName')}
                  placeholder={TEXT[lang].FULLNAME_OF_EMPLOYEES}
                  error={errors.fullName ? true : false}
                  helperText={errors.fullName?.message}
                  disabled={isLocked}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} algin="left">
                <TextField
                  margin="normal"
                  InputLabelProps={{
                    sx: {
                      '& .MuiInputLabel-asterisk': {
                        color: 'red',
                      },
                    },
                    style: labelStyle,
                  }}
                  InputProps={{ style: inputStyle }}
                  size="small"
                  sx={{ width: '100%', marginTop: 0 }}
                  {...register('role')}
                  placeholder={TEXT[lang].JOB_TITLE}
                  error={errors.role ? true : false}
                  helperText={errors.role?.message}
                  disabled={isLocked}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} algin="left">
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{ height: 'fit-content', '&.Mui-checked': { color: '#BC95F1' }, fontSize: 17 }}
                      checked={isReadyTalk}
                      onChange={(e) => setIsReadyTalk(e.target.checked)}
                    />
                  }
                  label={MESSAGES[lang].AVAILABLE_FOR_MEETING}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} algin="left">
                <Controller
                  name="since"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      placeholderText="Дата начала"
                      selected={field.value}
                      showTimeSelect
                      timeIntervals={20}
                      minTime={setHours(setMinutes(new Date(), 0), 8)}
                      maxTime={setHours(setMinutes(new Date(), 0), 21)}
                      timeFormat="HH:mm"
                      dateFormat="dd/MM HH:mm"
                      onChange={(date) => {
                        let _date = date;
                        if (date && date.getHours() < 8) {
                          _date = setHours(_date, 8);
                        }
                        if (date && date.getHours() > 21) {
                          _date = setHours(_date, 21);
                        }
                        field.onChange(_date);
                      }}
                      customInput={
                        <TextField
                          size="small"
                          margin="normal"
                          className={classes.input}
                          sx={{ ...inputStyle }}
                          error={errors.since ? true : false}
                          helperText={errors.since?.message}
                        />
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} algin="left">
                <Controller
                  name="till"
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      placeholderText="Дата окончания"
                      selected={field.value}
                      showTimeSelect
                      timeIntervals={20}
                      minTime={setHours(setMinutes(new Date(), 0), 8)}
                      maxTime={setHours(setMinutes(new Date(), 0), 21)}
                      timeFormat="HH:mm"
                      dateFormat="dd/MM HH:mm"
                      onChange={(date) => {
                        let _date = date;
                        if (date && date.getHours() < 8) {
                          _date = setHours(_date, 8);
                        }
                        if (date && date.getHours() > 21) {
                          _date = setHours(_date, 21);
                        }
                        field.onChange(_date);
                      }}
                      customInput={
                        <TextField
                          size="small"
                          margin="normal"
                          className={classes.input}
                          sx={{ ...inputStyle }}
                          error={errors.till ? true : false}
                          helperText={errors.till?.message}
                        />
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
            <div className={classes.buttonControl}>
              <Button type="submit" className={classes.buttonShow} variant="contained">
                {TEXT[lang].SEARCH}
                {/* {isInit
                ? `${TEXT[lang].TOTAL} ${filterTotal || 0} ${TEXT[lang].COMPANIES.toLowerCase()}`
                : `${TEXT[lang].SEARCH}`} */}
              </Button>
              <Button className={classes.buttonReset} endIcon={<CloseOutlined />} onClick={handleReset}>
                {TEXT[lang].RESET}
              </Button>
            </div>
          </Stack>
        </form>
        {/* <Button
        variant="contained"
        onClick={() => setShowFilters(!showFilters)}
        startIcon={<FilterListIcon />}
        sx={{
          marginTop: '16px',
          paddingTop: '8px',
          paddingBottom: '8px',
          backgroundColor: '#BC95F1',
          '&:hover': {
            backgroundColor: '#b976ff',
          },
        }}
      >
        Фильтры <i className="bi bi-filter text-white ms-1" />
      </Button> */}

        {/* {showFilters && <MeetingFilters filters={filters} onSubmit={(filters) => setFilters(filters)} />} */}

        {isSubmitting ? (
          <CircularProgress style={{ display: 'block', margin: '32px auto', color: '#BC95F1' }} />
        ) : (
          <Grid container className={classes.vizitWrapper} spacing={8} marginTop="24px" textAlign="center">
            {_results.map((company, i) => (
              <Grid item key={i} xs={12} md={6}>
                <CompanySection data={company} projectUuid={id} />
              </Grid>
            ))}
          </Grid>
        )}

        {!isSubmitting && !isLoadingMore && canLoadMore && (
          <InView onChange={handleLoadMore}>
            <div></div>
          </InView>
        )}
        {isLoadingMore && <CircularProgress style={{ display: 'block', margin: '32px auto 64px', color: '#BC95F1' }} />}
        <ConfirmAppointmentModal projectUuid={id} />
      </CardContent>
    </Card>
  );
};

export default VizitsSearchPage;
