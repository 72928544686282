// ** MUI Imports
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import MuiListSubheader from '@mui/material/ListSubheader';

// ** Custom Components Imports
import Translations from 'src/layouts/components/Translations';
import CanViewNavSectionTitle from 'src/layouts/components/acl/CanViewNavSectionTitle';

// ** Styled Components
const ListSubheader = styled((props) => <MuiListSubheader component="li" {...props} />)(({ theme }) => ({
  lineHeight: 1,
  display: 'flex',
  position: 'static',
  marginTop: theme.spacing(7),
  marginBottom: theme.spacing(2),
  backgroundColor: 'transparent',
}));

const TypographyHeaderText = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  lineHeight: '17px',
  letterSpacing: '0.21px',
  textTransform: 'uppercase',
  fontWeight: theme.typography.fontWeightMedium,
  textAlign: 'left',
  fontWeight: 'bold',
  color: 'black',
}));

const VerticalNavSectionTitle = (props) => {
  // ** Props
  const { item, navHover, settings, collapsedNavWidth, navigationBorderWidth } = props;

  // ** Vars
  const { navCollapsed } = settings;

  return (
    // <CanViewNavSectionTitle navTitle={item}>
    <ListSubheader
      className="nav-section-title"
      sx={{
        ...(navCollapsed && !navHover
          ? {
              py: 3.5,
              pr: (collapsedNavWidth - navigationBorderWidth - 24) / 8 - 1,
              pl: (collapsedNavWidth - navigationBorderWidth - 24) / 8 + 0.25,
            }
          : { px: 0, py: 1.75 }),

        margin: '30px 0 !important',
      }}
    >
      <Divider
        textAlign="left"
        sx={{
          m: '0 !important',
          lineHeight: 'normal',
          ...(navCollapsed && !navHover
            ? {
                width: 22,
                borderColor: (theme) => `rgba(${theme.palette.customColors.main}, 0.3)`,
              }
            : {
                width: '100%',
                textTransform: 'uppercase',
                '&:before, &:after': { top: 7, transform: 'none' },
                '& .MuiDivider-wrapper': { px: 2.5, fontSize: '0.75rem', letterSpacing: '0.21px' },
              }),
          alignItems: 'center',
          '&:before': { width: '30%' },
          '&:after': { width: '70%' },
        }}
      >
        {navCollapsed && !navHover ? null : (
          <TypographyHeaderText whiteSpace="normal">
            <Translations text={item.sectionTitle} />
          </TypographyHeaderText>
        )}
      </Divider>
    </ListSubheader>
    // </CanViewNavSectionTitle>
  );
};

export default VerticalNavSectionTitle;
