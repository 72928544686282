import { Grid, Stack } from '@mui/material';
import React from 'react';
import { LogoIcon } from '../../../../icons';
import { LanguageMuiSelect } from '../../../language-select';
import classes from './header.module.sass';

export function Header() {
  return (
    <Grid
      container
      spacing={1}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      alignContent="stretch"
      wrap="nowrap"
      className={classes.header}
    >
      <Stack direction="row">
        <LogoIcon color="#c5c6cb" />
      </Stack>
      <LanguageMuiSelect />
    </Grid>
  );
}
