import CloseIcon from '@mui/icons-material/Close';
import { CircularProgress, Dialog, DialogContent, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getServicesOfPackages } from '../../store/actions/app';
import { EVENTS, LOCALIZATION } from '../../utils/constant';
import eventBus from '../../utils/eventBus';
import classes from './package-detail-modal.module.sass';

export const PackageDetailModal = () => {
  const lang = useSelector((state) => state.app.lang) || LOCALIZATION.ru_RU;
  const [state, setState] = useState({
    show: false,
    isLoading: false,
    packages: null,
    onOk: () => {},
  });

  const fetchData = async (data) => {
    const { projectUuid, packages = [] } = data;
    const packageUuids = packages.map((p) => p.id);
    const packagesDetail = await getServicesOfPackages(projectUuid, packageUuids);
    setState({
      ...state,
      show: true,
      isLoading: false,
      packages: packages.map((p) => ({ ...p, services: packagesDetail[p.id] || [] })),
    });
  };

  const handleCancel = () => setState({ show: false });
  const handleShow = async (data) => {
    setState({ ...state, show: true, isLoading: true });
    await fetchData(data);
  };

  useEffect(() => {
    eventBus.on(EVENTS.OPEN_PACKAGE_DETAIL_MODAL, handleShow);
    return () => {
      eventBus.off(EVENTS.OPEN_PACKAGE_DETAIL_MODAL, handleShow);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Dialog
        sx={{
          '& .MuiDialog-paper': {
            minWidth: '50vw',
            minHeight: '60%',
            borderRadius: '30px',
            border: '4px solid #c1c1ff',
          },
        }}
        maxWidth="md"
        open={state.show}
        onClose={handleCancel}
      >
        <DialogContent>
          <IconButton
            aria-label="close"
            onClick={handleCancel}
            sx={{
              position: 'absolute',
              right: 16,
              top: 16,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          {state.isLoading ? (
            <div className={classes.loading}>
              <CircularProgress />
            </div>
          ) : (
            <div className={classes.body}>
              {state.packages?.map((_package, _i) => {
                const name = _package?.localization?.[lang]?.name?.trim();
                const description =
                  _package?.localization?.[lang]?.description ||
                  _package?.localization?.[LOCALIZATION.ru_RU]?.description;
                return (
                  <div key={_i}>
                    <div className={classes.packageTitle}>
                      «{' '}
                      {name && !name.includes('null')
                        ? name
                        : _package?.localization?.[LOCALIZATION.ru_RU]?.name?.trim()}{' '}
                      »
                    </div>
                    <div className={classes.packageDescription} dangerouslySetInnerHTML={{ __html: description }} />
                    {_package.services.map((service, i) => {
                      const title = service.localization?.[lang]?.title;
                      // const desc = service.localization?.[lang]?.description_max;
                      return (
                        <div key={`service-${i}`} className={classes.service}>
                          <div className={classes.title}>
                            {title && !title.includes('null')
                              ? title
                              : service.localization?.[LOCALIZATION.ru_RU]?.title}
                          </div>
                          {/* <div className={classes.desc}>
                            {desc && !desc.includes('null')
                              ? desc
                              : service.localization?.[LOCALIZATION.ru_RU]?.description_max}
                          </div> */}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};
