import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { EVENTS, LOCALIZATION } from '../../utils/constant';
import eventBus from '../../utils/eventBus';
import { CompanyForm } from '../company-form';
import classes from './company-form.module.sass';

export const CompanyFormModal = ({}) => {
  const [state, setState] = useState({
    show: false,
    data: null,
    onOk: () => {},
  });

  const handleCancel = () => setState({ show: false });
  const handleShow = async (data) => {
    setState({ ...state, show: true, data });
  };

  useEffect(() => {
    eventBus.on(EVENTS.OPEN_COMPANY_MODAL, handleShow);
    return () => {
      eventBus.off(EVENTS.OPEN_COMPANY_MODAL, handleShow);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Dialog
        sx={{
          '& .MuiDialog-paper': {
            minWidth: '50vw',
            minHeight: '60%',
            borderRadius: '30px',
            // border: '4px solid #c1c1ff',
          },
        }}
        maxWidth="md"
        open={state.show}
        onClose={handleCancel}
      >
        <DialogContent>
          <IconButton
            aria-label="close"
            onClick={handleCancel}
            sx={{
              position: 'absolute',
              right: 16,
              top: 16,
              zIndex: 1,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>

          <div className={classes.body}>
            <CompanyForm isLocked={false} />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
