import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Box, CircularProgress, IconButton } from '@mui/material';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { useBreakpoint } from 'src/hooks';
import { getUserProjects } from '../../store/actions/auth';
import { LOCALIZATION } from '../../utils/constant';
import ProjectCard from '../project-card';
import classes from './joined-project.module.sass';
import './style.scss';

export const JoinedProject = () => {
  const lang = useSelector((state) => state.app.lang) || LOCALIZATION.ru_RU;
  const projects = useSelector((state) => state.auth.projects);
  const user = useSelector((state) => state.auth.user);
  const isLoadingProjects = useSelector((state) => state.auth.isLoadingProjects);
  const { windowSize } = useBreakpoint();

  useEffect(() => {
    getUserProjects();
  }, []);

  const joinedProjects = projects.filter((project) => user?.projectUuids?.includes(project.uuid));

  if (isLoadingProjects) return <CircularProgress sx={{ margin: '2rem' }} />;

  return joinedProjects?.length > 0 ? (
    <div className={classes.root}>
      <Carousel
        centerSlidePercentage={windowSize.width > 1024 ? 70 : 90}
        showStatus={false}
        centerMode={joinedProjects.length > 1}
        showIndicators={false}
        showThumbs={false}
        swipeable={true}
        renderArrowPrev={(onClickHandler, hasPrev) =>
          hasPrev && (
            <IconButton className={classes.controlPrev} onClick={onClickHandler}>
              <ChevronLeft />
            </IconButton>
          )
        }
        renderArrowNext={(onClickHandler, hasNext) =>
          hasNext && (
            <IconButton className={classes.controlNext} onClick={onClickHandler}>
              <ChevronRight />
            </IconButton>
          )
        }
      >
        {joinedProjects.map((project, i) => {
          return (
            <Box className="slide-item" key={i}>
              <ProjectCard project={project} fullWidth={joinedProjects.length === 1} />
            </Box>
          );
        })}
      </Carousel>
    </div>
  ) : (
    <></>
  );
};
