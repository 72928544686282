import { ChevronLeft } from '@mui/icons-material';
import { Box, CardContent, Grid, IconButton, Stack, Typography } from '@mui/material';
import { memo } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DocumentFile from 'src/components/document-file';
import Loader from 'src/components/loader';
import { useLanguage } from 'src/hooks';
import { userAPIs } from 'src/services';
import { routes } from 'src/utils/routes';

function ProjectInfoFiles({ projectUuid }) {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);

  const { localizeMessage } = useLanguage();
  const { localizeText } = useLanguage();

  const fetchDocuments = async ({ queryKey }) => {
    const [, projectUuid, user] = queryKey;
    const response = await userAPIs.getDocuments({
      projectUuid,
      roleUuid: user?.projectSettings?.[projectUuid]?.mainRoleId,
      type: 'document_information',
    });
    return response.message || [];
  };

  const { isLoading, data: documents } = useQuery(['fetchDocuments', projectUuid, user], fetchDocuments, {
    enabled: !!projectUuid && !!user?.projectSettings?.[projectUuid]?.mainRoleId,
    staleTime: 0,
  });

  return (
    <Box sx={{ minHeight: '50vh' }}>
      <Stack direction="row" gap="8px" alignItems="center">
        <IconButton onClick={() => navigate(routes.projectDetail.path.replace(':projectUuid', projectUuid))}>
          <ChevronLeft />
        </IconButton>
        <h2 style={{ margin: 0, fontWeight: 500 }}>{localizeText.INFORMATION_FILES}</h2>
      </Stack>
      {isLoading ? (
        <Loader />
      ) : documents?.length > 0 ? (
        <Grid spacing={6} container justifyContent="center">
          {documents?.map((document, index) => (
            <Grid item key={index} xs={12} md={6}>
              <DocumentFile document={document} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Typography variant="h6" textAlign="center">
          {localizeMessage.NO_DATA}
        </Typography>
      )}
    </Box>
  );
}

export default memo(ProjectInfoFiles);
