import React from 'react';

function BriefcaseIcon({ className }) {
  return (
    <svg
      className={className}
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 26.5624C0 27.4078 0.335825 28.2185 0.933597 28.8163C1.53137 29.4141 2.34212 29.7499 3.1875 29.7499H30.8125C31.6579 29.7499 32.4686 29.4141 33.0664 28.8163C33.6642 28.2185 34 27.4078 34 26.5624V14.5562L17.2741 19.0123C17.0945 19.0602 16.9055 19.0602 16.7259 19.0123L0 14.5562V26.5624Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 9.5625C0 8.71712 0.335825 7.90637 0.933597 7.3086C1.53137 6.71083 2.34212 6.375 3.1875 6.375H30.8125C31.6579 6.375 32.4686 6.71083 33.0664 7.3086C33.6642 7.90637 34 8.71712 34 9.5625V12.5035L17.8202 16.8173C17.2828 16.9604 16.7172 16.9604 16.1798 16.8173L0 12.5035V9.5625ZM10.625 5.3125C10.625 4.46712 10.9608 3.65637 11.5586 3.0586C12.1564 2.46083 12.9671 2.125 13.8125 2.125H20.1875C21.0329 2.125 21.8436 2.46083 22.4414 3.0586C23.0392 3.65637 23.375 4.46712 23.375 5.3125V6.375H21.25V5.3125C21.25 5.03071 21.1381 4.76046 20.9388 4.5612C20.7395 4.36194 20.4693 4.25 20.1875 4.25H13.8125C13.5307 4.25 13.2605 4.36194 13.0612 4.5612C12.8619 4.76046 12.75 5.03071 12.75 5.3125V6.375H10.625V5.3125Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default BriefcaseIcon;
