import { ChevronLeft } from '@mui/icons-material';
import { Box, CardContent, Checkbox, CircularProgress, IconButton, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import OrderItem from 'src/components/order/OrderItem';
import { useAllowedNetworking, useLanguage } from 'src/hooks';
import { getMembersByProject } from 'src/store/actions/app';
import { routes } from 'src/utils/routes';
import { Button } from '../../components';
import { getMyOrder } from '../../store/actions/auth';

const MyOrderPage = ({ project }) => {
  const myOrders = useSelector((state) => state.auth.myOrders);
  const members = useSelector((state) => state.app.members);

  const navigate = useNavigate();
  const { projectUuid: id } = useParams();
  const { isAllowedNetworking } = useAllowedNetworking();
  const { localizeText } = useLanguage();

  const [isLoading, setIsLoading] = useState(true);
  const [selectedOrders, setSelectedOrders] = useState([]);

  useEffect(() => {
    if (id) {
      Promise.all([getMembersByProject({ projectUuid: id }), getMyOrder(id)]).finally(() => setIsLoading(false));
    }
  }, [id]);

  const ableDistribute = members?.length > 0;

  const isPaidStatus = (order) => ['confirmed', 'approved', 'paid'].includes(order.status);

  return (
    <div>
      <Stack direction="row" justifyContent="space-between" alignItems="center" flexWrap="wrap" gap="16px">
        <Stack direction="row" gap="8px" alignItems="center">
          <IconButton onClick={() => navigate(routes.projectDetail.path.replace(':projectUuid', id))}>
            <ChevronLeft />
          </IconButton>
          <h2 style={{ margin: 0, fontWeight: 500 }}>{localizeText.ORDERS}</h2>
        </Stack>
        <Stack direction="row" gap="8px">
          {/* {isAllowedNetworking && (
            <Button
              onClick={() => navigate(routes.projectVizits.path.replace(':projectUuid', id))}
              sx={{
                height: 'fit-content',
                borderRadius: '4px !important',
                textTransform: 'none',
              }}
            >
              {localizeText.START_NETWORKING}
            </Button>
          )} */}
          {ableDistribute && selectedOrders.length > 0 && (
            <Button
              onClick={() =>
                navigate(routes.projectDistribute.path.replace(':projectUuid', id), { state: { selectedOrders } })
              }
              sx={{
                height: 'fit-content',
                borderRadius: '4px !important',
                textTransform: 'none',
              }}
            >
              {localizeText.DISTRIBUTE_ORDERS}
            </Button>
          )}
          <Button
            onClick={() => navigate(routes.archivedOrders.path.replace(':projectUuid', id))}
            sx={{
              height: 'fit-content',
              borderRadius: '4px !important',
              textTransform: 'none',
            }}
          >
            {localizeText.ARCHIVED_ORDERS}
          </Button>
        </Stack>
      </Stack>
      {isLoading ? (
        <CircularProgress style={{ display: 'block', margin: 'auto', color: '#BC95F1' }} />
      ) : (
        <Box sx={{ background: 'transparent', borderRadius: '30px', marginTop: '16px' }}>
          {myOrders.length ? (
            <Stack alignContent="flex-start" gap="24px">
              {myOrders.map((order, index) => (
                <Stack key={index} direction={{ xs: 'column', md: 'row' }} gap="8px" alignItems="center">
                  {ableDistribute && (
                    <Box>
                      <Checkbox
                        checked={selectedOrders.includes(order.id)}
                        disabled={!isPaidStatus(order)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedOrders([...selectedOrders, order.id]);
                          } else {
                            setSelectedOrders(selectedOrders.filter((orderId) => orderId !== order.id));
                          }
                        }}
                      />
                    </Box>
                  )}
                  <OrderItem order={order} project={project} />
                </Stack>
              ))}
            </Stack>
          ) : (
            <p style={{ fontSize: 18, textAlign: 'center' }}>{localizeText.NO_DATA}</p>
          )}
        </Box>
      )}
    </div>
  );
};

export default MyOrderPage;
