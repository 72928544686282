import { Container, Grid, MenuItem, Select, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useCurrentPath } from 'src/hooks';
import UserLayout from 'src/layouts/UserLayout';
import { getProjectsList } from 'src/store/actions/app';
import { routes } from 'src/utils/routes';
import { Breadcrumbs } from '../breadcrumbs';
import SelectCurrentProject from '../select-current-project';
import Drawer from './components/drawer';
import classes from './main-layout.module.sass';
import qs from 'query-string';
import Loader from '../loader';
import { UserProject } from '../user-project';

export function MainLayout({ breadcrumbs = [], fullHeight, contentHeightFixed, children }) {
  // const [toggleMenu, setToggleMenu] = useState(false);
  const { projectUuid } = useParams();
  const [project, setProject] = useState();
  const [isLoading, setIsLoading] = useState(!!projectUuid);

  const { path: basePath, canChangeCurrentProject } = useCurrentPath();

  // const fetchProjectData = async () => {
  //   const projects = await getProjectsList(qs.stringify({ uuid: projectUuid }), false);
  //   if (projects.length > 0) {
  //     const _project = projects[0];
  //     setProject(_project);
  //   }
  //   setIsLoading(false);
  // };

  // useEffect(() => {
  //   if (projectUuid) {
  //     fetchProjectData();
  //   }
  // }, [projectUuid]);

  return (
    // <Container disableGutters maxWidth={false} className={classes.root}>
    //   <Grid className={classes.container} container spacing={1} direction="row" justify="flex-start" wrap="nowrap">
    //     <Drawer isToggle={toggleMenu} openMenu={() => setToggleMenu(true)} closeMenu={() => setToggleMenu(false)} />
    //     <div className={classes.content}>
    //       <Breadcrumbs
    //         className={classes.breadcrumbs}
    //         breadcrumbs={breadcrumbs}
    //         onToggleMenu={() => setToggleMenu(true)}
    //       />
    //       {children}
    //     </div>

    //     <div className={classes.copyRight} style={{ background: location.pathname === '/order' && 'transparent' }}>
    //       Copyright © 2022 ExpoForum
    //     </div>
    //   </Grid>
    // </Container>
    <UserLayout contentHeightFixed={contentHeightFixed}>
      <Stack sx={{ height: fullHeight ? '100%' : 'inherit' }}>
        <Stack gap="4rem" direction="row" justifyContent="space-between" alignItems="center">
          <Breadcrumbs
            className={classes.breadcrumbs}
            breadcrumbs={breadcrumbs}
            // onToggleMenu={() => setToggleMenu(true)}
          />
          {/* {canChangeCurrentProject && (
            <Stack
              sx={{
                display: { xs: 'flex', md: 'none' },
                overflow: 'hidden',
                padding: { xs: '0 1rem 1rem', md: 0 },
                width: { xs: '100%', md: 'auto' },
              }}
            >
              <SelectCurrentProject basePath={basePath} />
            </Stack>
          )} */}
        </Stack>
        {/* {isLoading ? <Loader /> : <>{project && <UserProject project={project} />}</>} */}
        {children}
      </Stack>
    </UserLayout>
  );
}
