import CameraAltIcon from '@mui/icons-material/CameraAlt';
import ClearIcon from '@mui/icons-material/Clear';
import { Skeleton } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import React, { useMemo, useRef, useState } from 'react';
import { uploadAvatarUser } from '../../store/actions/app';
import { EVENTS } from '../../utils/constant';
import eventBus from '../../utils/eventBus';
import classes from './avatar.module.sass';

export const AvatarUser = ({ user, isMain, hideUploadAvatar, setAvatar = () => {} }) => {
  const inputFileRef = useRef();
  const removeImg = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [imgSrc, setImgSrc] = useState(null);
  // const isLocked = user?.isLocked;

  const avatarURL = useMemo(() => {
    try {
      return URL.createObjectURL(user.avatarFile);
    } catch (error) {
      return '';
    }
  }, [user]);

  const handleOnChange = async (newImage) => {
    if (newImage) {
      setIsLoading(true);
      const success = await uploadAvatarUser(user.uuid, newImage);
      if (success) {
        setAvatar(newImage);
        setImgSrc(URL.createObjectURL(newImage));
      }
      setIsLoading(false);
    }
  };

  const handleOpenFile = (e) => {
    e.stopPropagation();
    if (avatarURL) {
      eventBus.emit(EVENTS.OPEN_VIEW_IMAGE_MODAL, {
        images: [{ src: avatarURL }],
        fileName: 'avatar.png',
      });
    }
  };

  const handleRemove = () => {
    URL.revokeObjectURL(imgSrc);
    inputFileRef.current.value = null;
    setImgSrc(null);
    removeImg.current.classList.remove('enable');
  };

  const handleMouseEnter = (e) => {
    e.stopPropagation();
    if (imgSrc) removeImg.current.classList.add('enable');
  };

  const handleMouseLeave = (e) => {
    e.stopPropagation();
    if (imgSrc) removeImg.current.classList.remove('enable');
  };

  return (
    <div className={classes.root}>
      <input
        ref={inputFileRef}
        accept="image/*"
        className={classes.inputAvatar}
        id={`avatar-user-${user.uuid}`}
        multiple={false}
        type="file"
        onChange={handleOnChange}
      />
      <div
        className={classes.avatarWrapper}
        onClick={handleOpenFile}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <label>
          {isLoading ? (
            <Skeleton variant="circular" className={classes.avatar} />
          ) : (
            <Avatar src={avatarURL} sx={{ background: '#9D9D9D !important' }} className={classes.avatar}>
              {(user?.lastName?.[0] || '') + (user?.firstName?.[0] || '')}
            </Avatar>
          )}
        </label>
        {!hideUploadAvatar && (
          <label
            // htmlFor={`avatar-user-${user.uuid}`}
            className={classes.cameraLabel}
            onClick={(e) => {
              e.stopPropagation();
              eventBus.emit(EVENTS.OPEN_IMAGE_CROPPER_MODAL, {
                onOk: handleOnChange,
              });
            }}
          >
            <CameraAltIcon />
          </label>
        )}
        <div className={classes.removeImg} ref={removeImg}>
          <div className="icon-wrapper" onClick={handleRemove}>
            <ClearIcon className="icon" />
          </div>
        </div>
      </div>
    </div>
  );
};
