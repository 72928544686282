import { Card, Stack, Tooltip, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { getFullName } from '../../utils/common';
import { AvatarUser } from '../avatar-user';
import ButtonMyTicket from '../button-my-ticket';
import UserRole from '../user-role';
import UserStatus from '../user-status';
import classes from './main-profile.module.sass';

export function MainUserProfile({
  user = {},
  role,
  projectUuid,
  hideAvatar,
  hideInfo,
  sx,
  hideUploadAvatar,
  ...restProps
}) {
  const currentUser = useSelector((state) => state.auth.user);

  const isCurrentUser = currentUser?.uuid === user?.uuid;
  const isAccredited = user?.projectSettings?.[projectUuid]?.isAccredited;
  const readyToAccreditate = user?.projectSettings?.[projectUuid]?.readyToAccreditate;

  return (
    <Card
      sx={{
        color: (theme) => (isCurrentUser ? theme.palette.primary.contrastText : theme.palette.common.black),
        ...sx,
        background: (theme) => (isCurrentUser ? theme.palette.primary.main : theme.palette.grey[300]),
      }}
      className={classes.root}
      {...restProps}
    >
      <Stack gap="10px" justifyContent="center" alignItems="center">
        {hideAvatar ? <></> : <AvatarUser user={user} hideUploadAvatar={hideUploadAvatar} />}

        <Stack alignItems="center" className={classes.mainSection}>
          <Tooltip title={getFullName(user)}>
            <Typography className={`${classes.name} limit-row overflow-wrap-any`}>{getFullName(user)}</Typography>
          </Tooltip>
          <Tooltip title={user.email}>
            <Typography style={{ fontSize: '14px' }} className={`${classes.email} limit-row overflow-wrap-any`}>
              {user.email}
            </Typography>
          </Tooltip>

          {hideInfo ? (
            <></>
          ) : (
            <Stack marginTop="16px" gap="8px" alignItems="center">
              <UserRole role={role} sx={{ alignItems: 'center', display: 'flex' }} />
              <Stack gap="8px" direction="row" justifyContent="center" alignItems="center" flexWrap="wrap">
                <UserStatus
                  isAccredited={isAccredited}
                  readyToAccreditate={readyToAccreditate}
                  sx={{ height: '30px', alignItems: 'center', display: 'flex' }}
                />
                {isAccredited && (
                  <ButtonMyTicket userUuid={user.uuid} projectUuid={projectUuid} sx={{ height: '30px' }} />
                )}
              </Stack>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Card>
  );
}
