import { ORDER_DIRECTION } from 'src/utils/constant';
import PropTypes from 'prop-types';
import {
  alpha,
  Checkbox,
  CircularProgress,
  Divider,
  IconButton,
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import TableSortLabel from './TableSortLabel';
import Loader from '../loader';
import { uniqueId } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'query-string';
import { Icon } from '@iconify/react';
import { useLanguage } from 'src/hooks';

export { default as TableSortLabel } from './TableSortLabel';

export function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
}

export function getComparator(order, orderBy) {
  if (order === '') return () => 1;
  return order === ORDER_DIRECTION.DESC
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const EnhancedTableToolbar = (props) => {
  const { selected, numSelected, renderBulkActions, onDeSelectAll } = props;

  return (
    numSelected > 0 && (
      <Toolbar
        sx={{
          px: (theme) => `${theme.spacing(5)} !important`,
          bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        <Stack direction="row" alignItems="center" flexWrap="wrap" gap="8px">
          <Typography
            color="inherit"
            variant="subtitle1"
            component="div"
            sx={{
              paddingRight: (theme) => `${theme.spacing(5)} !important`,
              borderRight: '1px solid',
              borderColor: (theme) => theme.palette.text.disabled,
            }}
          >
            {numSelected} выбран
          </Typography>
          {renderBulkActions ? renderBulkActions(selected) : null}
        </Stack>
        <IconButton sx={{ color: 'text.secondary' }} onClick={onDeSelectAll}>
          <Icon icon="mdi:close" />
        </IconButton>
      </Toolbar>
    )
  );
};

const TableLoading = ({ rowsNum = 3, columnsNum }) => {
  return (
    <TableRow>
      <TableCell rowSpan={rowsNum} colSpan={columnsNum} sx={{ height: '30vh' }}>
        <Loader />
      </TableCell>
    </TableRow>
  );

  return [...Array(rowsNum)].map((_, rowIndex) => (
    <TableRow key={`row-loading__${rowIndex}`}>
      {[...Array(columnsNum)].map((_, columnIndex) => (
        <TableCell key={`row-${rowIndex}-loading__${columnIndex}`}>
          <Skeleton animation="wave" variant="text" />
        </TableCell>
      ))}
    </TableRow>
  ));
};

export const MUITable = ({
  id,
  headCells: baseHeadCells,
  loading,
  rows,
  rowsNum,
  selectable: baseSelectable,
  isDisableSelect,
  renderBulkActions,
  onClickRow,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { localizeText, localizeMessage } = useLanguage();

  const [selected, setSelected] = useState([]);

  const headCells = (baseHeadCells || []).filter((cell) => !cell.hidden);
  const filteredDisableSelectRows = isDisableSelect ? rows.filter((row) => !isDisableSelect(row)) : rows;
  const selectable = baseSelectable && filteredDisableSelectRows.length > 0;

  // GET queries from URL params
  const queryParams = qs.parse(location.search);
  const { page: queryPage, limit, sortOrder: querySortOrder, sortBy = '' } = queryParams;
  const rowsPerPage = parseInt(limit) || 20;
  const page = Math.max(queryPage || 1, 1);
  const sortOrder = (querySortOrder || ORDER_DIRECTION.ASC).toUpperCase();

  const setQueryParams = useCallback(
    ({ ...newParams }) => {
      const newKeys = Object.keys({ ...newParams, page: newParams.page || 1 });
      const query = qs.stringify({
        ...Object.entries(queryParams)
          .filter((set) => newKeys.includes(set[0])) // removing current values, so they can be overwritten by new if new are nulls
          .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {}),
        ...newParams,
      });
      navigate(location.pathname + '?' + query);
    },
    [queryParams]
  );

  const handleRequestSort = (property) => {
    const isAsc = sortBy === property && sortOrder === ORDER_DIRECTION.ASC;
    const isDesc = sortBy === property && sortOrder === ORDER_DIRECTION.DESC;
    const newQueryParams = {
      ...queryParams,
      sortOrder: (isAsc ? ORDER_DIRECTION.DESC : isDesc ? '' : ORDER_DIRECTION.ASC).toLowerCase(),
      sortBy: property,
    };
    if (!isAsc && isDesc) {
      delete newQueryParams.sortBy;
      delete newQueryParams.sortOrder;
    }
    setQueryParams(newQueryParams);
  };

  const handleChangePage = (event, newPage) => {
    setQueryParams({ ...queryParams, page: newPage + 1 });
  };

  const handleChangeRowsPerPage = (event) => {
    setQueryParams({ ...queryParams, page: 1, limit: parseInt(event.target.value, 10) });
  };

  const preventSpreadEvent = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelectedOnes = filteredDisableSelectRows.map((n) => n.uuid);
      setSelected(newSelectedOnes);
      return;
    }
    setSelected([]);
  };

  const handleSelectRow = (rowUuid) => {
    const selectedIndex = selected.indexOf(rowUuid);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, rowUuid);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const isSelected = (rowUuid) => selected.indexOf(rowUuid) !== -1;
  const numSelected = selected.length;
  const rowsLength = filteredDisableSelectRows.length;

  useEffect(() => {
    setSelected([]);
  }, [rows]);

  return (
    <Stack gap="8px">
      <Stack>
        <EnhancedTableToolbar
          selected={selected}
          numSelected={selected.length}
          renderBulkActions={renderBulkActions}
          onDeSelectAll={() => setSelected([])}
        />
        <TableContainer id={id} component={Paper} sx={{ maxHeight: 'calc(100vh - 200px)' }}>
          <Table stickyHeader={true} size="medium" sx={{ minWidth: 750 }}>
            <TableHead sx={{ position: 'relative', zIndex: 2 }}>
              <TableRow sx={{ background: (theme) => theme.palette?.customColors?.tableHeaderBg }}>
                {selectable && (
                  <TableCell padding="checkbox" onClick={handleSelectAllClick}>
                    <Checkbox
                      checked={rowsLength > 0 && numSelected === rowsLength}
                      inputProps={{ 'aria-label': 'select all desserts' }}
                      indeterminate={numSelected > 0 && numSelected < rowsLength}
                    />
                  </TableCell>
                )}
                {headCells.map((headCell, indexHead) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.numeric ? 'right' : 'left'}
                    padding={headCell.disablePadding ? 'none' : 'normal'}
                    width={headCell.width}
                  >
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      sx={{ whiteSpace: 'nowrap', width: headCell.disablePadding ? '100%' : 'calc(100% + 16px)' }}
                    >
                      {headCell.isSortable ? (
                        <TableSortLabel
                          headCell={headCell}
                          order={sortOrder}
                          orderBy={sortBy}
                          onRequestSort={handleRequestSort}
                        />
                      ) : (
                        <Typography variant="body1" sx={{ fontSize: '0.75rem', fontWeight: 600, lineHeight: '24px' }}>
                          {headCell.label}
                        </Typography>
                      )}
                      {indexHead < headCells.length - 1 && (
                        <Divider
                          orientation="vertical"
                          flexItem
                          sx={{ width: '2px', height: '14px !important', margin: 'auto 0' }}
                        />
                      )}
                    </Stack>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody sx={{ position: 'relative', zIndex: 1 }}>
              {loading ? (
                <TableLoading columnsNum={(selectable ? 1 : 0) + headCells.length} />
              ) : rows.length > 0 ? (
                <>
                  {rows.map((row, rowIndex) => {
                    const isItemSelected = isSelected(row.uuid) || false;
                    return (
                      <TableRow
                        hover
                        key={`row__${rowIndex}`}
                        sx={{ cursor: onClickRow ? 'pointer' : 'default' }}
                        selected={isSelected(row.uuid)}
                        onClick={() => (onClickRow ? onClickRow(row) : undefined)}
                      >
                        {selectable && (
                          <TableCell padding="checkbox" onClick={preventSpreadEvent}>
                            {(isDisableSelect ? !isDisableSelect(row) : true) && (
                              <Checkbox checked={isItemSelected} onClick={() => handleSelectRow(row.uuid)} />
                            )}
                          </TableCell>
                        )}
                        {headCells.map((cell, cellIndex) => (
                          <TableCell
                            key={`row-${rowIndex}-cell__${cellIndex}`}
                            padding={cell.disablePadding ? 'none' : 'normal'}
                            // onClick={preventSpreadEvent}
                          >
                            {cell.renderRow ? (
                              cell.renderRow(row, rowIndex)
                            ) : (
                              <Typography variant="body2">{row[cell.id]}</Typography>
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    );
                  })}
                </>
              ) : (
                <TableRow sx={{ height: 200 }}>
                  <TableCell colSpan={headCells.length}>
                    <Typography align="center">{localizeText.NO_DATA}</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
      {rowsNum > 0 && (
        <TablePagination
          labelRowsPerPage={localizeMessage.ROWS_PER_PAGE}
          labelDisplayedRows={({ count, from, to }) => {
            return `${from}-${to} ${localizeText.OF} ${count}`;
          }}
          page={page - 1}
          component="div"
          count={rowsNum}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[10, 20, 50]}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Stack>
  );
};

MUITable.propTypes = {
  id: PropTypes.string,
  headCells: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      disablePadding: PropTypes.bool,
      width: PropTypes.number,
      isSortable: PropTypes.bool,
      renderRow: PropTypes.func,
      minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string.isRequired,
    })
  ).isRequired,
  rowsNum: PropTypes.number,
  selectable: PropTypes.bool,
  isDisableSelect: PropTypes.func,
  renderBulkActions: PropTypes.func,
  onClickRow: PropTypes.func,
};

MUITable.defaultProps = {
  id: uniqueId('table__'),
  headCells: [],
  rows: [],
  rowsNum: 0,
};
