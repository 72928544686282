import { Box, Button, CircularProgress, styled, Typography } from '@mui/material';
import React from 'react';
import Loader from '../loader';

const MuiButton = styled(Button)(({ theme }) => ({
  background: theme.palette.common.white,
  color: theme.palette.common.black,
  padding: '1rem 3rem',
  fontSize: '1.25rem',
  fontWeight: 400,
  width: '100%',
  height: '100%',
  textAlign: 'left',
  boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
  position: 'relative',
  gap: '8px',
  justifyContent: 'flex-start',
  '& svg': {
    color: theme.palette.icon.primary,
    width: '2rem',
    height: '2rem',
  },
  '&:hover': {
    color: theme.palette.common.white,
    '& svg': {
      color: theme.palette.common.white,
    },
  },
}));

function NavigateCard({ label, icon, extraElement = <></>, disabled, isLoading, onClick }) {
  return (
    <MuiButton
      variant="contained"
      startIcon={
        isLoading ? (
          <Box>
            <CircularProgress sx={{ width: 'inherit !important', height: 'inherit !important'}} />
          </Box>
        ) : (
          icon
        )
      }
      onClick={onClick}
      sx={{ cursor: onClick ? 'pointer' : 'unset' }}
      disabled={disabled || isLoading}
    >
      {label}
      {extraElement}
    </MuiButton>
  );
}

export default NavigateCard;
