import { Card, CardContent, Chip, IconButton, Stack, Typography } from '@mui/material';
import { keyBy } from 'lodash';
import { useMemo, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import { useLanguage } from 'src/hooks';
import { deleteOrder } from 'src/store/actions/auth';
import { getFullName } from 'src/utils/common';
import { EVENTS, PAYMENT_METHODS, STATUS_PAYMENT } from 'src/utils/constant';
import eventBus from 'src/utils/eventBus';
import { viewFile } from 'src/utils/request';
import DeleteIcon from '../../icons/delete';
import classes from './order.module.sass';

function OrderItem({ order, project, prefixOrderId = '', showPayment = true, showDelete = true }) {
  const members = useSelector((state) => state.app.members);
  const user = useSelector((state) => state.auth.user);

  const { lang, localizeMessage, localizeText } = useLanguage();

  const [isDownloading, setIsDownloading] = useState(false);
  const memberByUuid = useMemo(() => keyBy(members || [], 'uuid'), [members]);

  const handleDownFile = async (docUuid) => {
    try {
      if (isDownloading) return;
      setIsDownloading(true);
      await viewFile(`/users/current/documents/${docUuid}`);
    } finally {
      setIsDownloading(false);
    }
  };

  const handleDeleteOrder = (order) => {
    eventBus.emit(EVENTS.OPEN_CONFIRM_MODAL, {
      onOk: () => {
        deleteOrder(order.id);
      },
    });
  };

  const handleShowDetail = (projectUuid, products) => {
    eventBus.emit(EVENTS.OPEN_PACKAGE_DETAIL_MODAL, {
      projectUuid,
      packages: products.map((p) => ({
        ...p,
        localization: {
          [lang]: {
            name: p.comment,
          },
        },
        id: p.productId,
      })),
    });
  };

  if (!order || !project) return null;

  const date = new Date(order.createdAt * 1000);
  const paymentMethod = order.trxId ? PAYMENT_METHODS.bankCard.label[lang] : PAYMENT_METHODS.bankAccount.label[lang];
  const projectLogo = project.photos?.logo?.[0];

  return (
    <Card className={classes.root}>
      <CardContent>
        {showDelete && order.status === 'undetermined' && (
          <IconButton className={classes.deleteIcon} onClick={() => handleDeleteOrder(order)}>
            <DeleteIcon />
          </IconButton>
        )}

        <Stack direction={{ xs: 'column', md: 'row' }} gap="18px" alignItems={{ xs: 'flex-start', md: 'center' }}>
          <Stack>
            <div className={classes.number}>
              {prefixOrderId} {localizeText.ORDER} {date.getDate()}/{date.getMonth() + 1}/{date.getFullYear()}
            </div>
            <img className={classes.image} width="150" height="150" src={projectLogo} />
          </Stack>

          <Stack width="100%" justifyContent="space-evenly" padding={{ xs: '0px', md: '24px 0' }}>
            <Stack
              className={classes.top}
              direction={{ xs: 'column', md: 'row' }}
              justifyContent="space-between"
              sx={{ gap: '18px' }}
            >
              <Stack>
                <Stack direction="row" gap="8px" flexWrap="wrap">
                  <div className={classes.invoice}>{paymentMethod}</div>
                  {order.thezisId && (
                    <div className={classes.orderNumber}>{`${localizeText.NUMBER_ORDER}: ${order.thezisId}`}</div>
                  )}
                  {user?.uuid !== order.ownerId && (
                    <div className={classes.orderNumber}>{localizeText.GENERAL_ORDER}</div>
                  )}
                </Stack>
                <div className={classes.name} style={{ marginBottom: 12 }}>
                  {project.localization?.[lang]?.fullName || project.name}
                </div>
                <Stack spacing={0.5}>
                  {order.products?.map((product, indexP) => (
                    <Stack
                      direction="row"
                      flexWrap="wrap"
                      alignItems="center"
                      gap="8px"
                      key={indexP}
                      className={classes.product}
                    >
                      <span>
                        {product.comment} x {product.amount}
                      </span>
                      {product.userId !== order.ownerId && <Chip label={getFullName(memberByUuid[product.userId])} />}
                    </Stack>
                  ))}
                  <Typography
                    textAlign="left"
                    color="#BC95F1"
                    sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}
                    onClick={() => handleShowDetail(project.uuid, order.products)}
                  >
                    {localizeText.SHOW_PACKAGE_DETAILS}
                  </Typography>
                </Stack>
              </Stack>
              {showPayment && (
                <Stack>
                  <div className={classes.invoice} style={{ background: STATUS_PAYMENT[order.status]?.color }}>
                    {STATUS_PAYMENT[order.status].label[lang]}
                  </div>

                  <div className={classes.price} style={{ margin: '20px 0' }}>
                    <NumberFormat
                      value={order.totalSum}
                      suffix=" руб"
                      decimalScale={2}
                      displayType="text"
                      thousandSeparator=" "
                      decimalSeparator=","
                    />
                  </div>
                  {order.properties?.invoiceDocId && (
                    <div
                      className={classes.viewPDF}
                      style={{ color: isDownloading && 'grey', cursor: isDownloading && 'auto' }}
                      onClick={() => handleDownFile(order.properties?.invoiceDocId)}
                    >
                      «Скачать счет»
                    </div>
                  )}
                </Stack>
              )}
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}

export default OrderItem;
