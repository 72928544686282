import { Button, CircularProgress } from '@mui/material';
import cx from 'classnames';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { routes } from 'src/utils/routes';
import { DashboardIcon2 } from '../../icons';
import { isSafari } from '../../utils/common';
import { LOCALIZATION, TEXT } from '../../utils/constant';
import classes from './button-my-ticket.module.sass';

function ButtonMyTicket({
  userUuid,
  projectUuid,
  color = 'primary',
  className,
  useMuiColor,
  showIcon = true,
  ...restProps
}) {
  const lang = useSelector((state) => state.app.lang) || LOCALIZATION.ru_RU;
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleGotoMyTicket = async (e) => {
    if (!userUuid) return;
    e.stopPropagation();
    e.preventDefault();
    const url = routes.myTicket.path.replace(':userUuid', userUuid).replace(':projectUuid', projectUuid);
    if (isSafari) {
      navigate(url);
    } else {
      window.open(url, '_blank');
    }
  };

  return (
    <Button
      className={cx(className, useMuiColor ? false : classes.root)}
      color={color}
      endIcon={
        isLoading ? <CircularProgress size={12} color="inherit" /> : showIcon ? <DashboardIcon2 color="#000" /> : null
      }
      variant="contained"
      onClick={handleGotoMyTicket}
      disabled={isLoading}
      {...restProps}
    >
      {TEXT[lang].MY_TICKET}
    </Button>
  );
}

export default ButtonMyTicket;
