import BadgeIcon from '@mui/icons-material/Badge';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded';
import { routes } from 'src/utils/routes';

export const getProjectExtraNavItems = (projectData) => {
  const extraNavItems = projectData
    ? [
      { sectionTitle: projectData.name },
      {
        title: 'Участники',
        userIcon: 'mdi:account-box-multiple',
        path: routes.projectsUsers.path.replace(':uuid', projectData.uuid),
        inactive: true,
      },
      {
        title: 'Статистика',
        userIcon: 'ic:baseline-query-stats',
        path: routes.projectsPage.path.replace(':uuid', projectData.uuid),
        inactive: true,
      },
      {
        title: 'Встречи',
        userIcon: 'mdi:user-group',
        path: routes.projectsMeetings.path.replace(':uuid', projectData.uuid),
        inactive: true,
      },
      { title: 'Компании', icon: <BadgeIcon />, path: routes.projectVizits.path.replace(':uuid', projectData.uuid) },
      { title: 'Мониторинг', userIcon: 'ic:outline-remove-red-eye' },
      { title: 'Обращения', userIcon: 'mdi:email-mark-as-unread' },
      { title: 'Заказы', userIcon: 'ic:baseline-shopping-cart' },
      { title: 'Скуд', userIcon: 'mdi:shield' },
      {
        title: 'Настройки',
        userIcon: 'mdi:build',
        path: routes.projectSettings.path.replace(':uuid', projectData.uuid),
      },
      {
        title: 'Регистрация',
        icon: <PersonAddAltRoundedIcon />,
        path: routes.projectRegisters.path.replace(':uuid', projectData.uuid),
      },
    ]
    : [];

  return extraNavItems;
};

const navigation = (removeExactProjectsPath) => {
  return [
    {
      title: 'Главная',
      icon: <HomeOutlinedIcon />,
      path: '/',
      exact: true,
      // badgeContent: 'new',
      // badgeColor: 'error',
      // children: [
      //   {
      //     title: 'CRM',
      //     path: '/dashboards/crm',
      //   },
      //   {
      //     title: 'Analytics',
      //     path: '/dashboards/analytics',
      //   },
      //   {
      //     title: 'eCommerce',
      //     path: '/dashboards/ecommerce',
      //   },
      // ],
    },
    {
      title: 'Все мероприятия',
      icon: <CircleOutlinedIcon sx={{ fontSize: '14px', width: '20px' }} />,
      path: '/projects',
      exact: removeExactProjectsPath,
    },
  ];
};

export default navigation;
