import React from 'react';

export function DashboardIcon2({ color = '#FFF' }) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 8H8V0H0V8ZM2 2H6V6H2V2Z" fill={color} />
      <path d="M0 18H8V10H0V18ZM2 12H6V16H2V12Z" fill={color} />
      <path d="M10 0V8H18V0H10ZM16 6H12V2H16V6Z" fill={color} />
      <path d="M18 16H16V18H18V16Z" fill={color} />
      <path d="M12 10H10V12H12V10Z" fill={color} />
      <path d="M14 12H12V14H14V12Z" fill={color} />
      <path d="M12 14H10V16H12V14Z" fill={color} />
      <path d="M14 16H12V18H14V16Z" fill={color} />
      <path d="M16 14H14V16H16V14Z" fill={color} />
      <path d="M16 10H14V12H16V10Z" fill={color} />
      <path d="M18 12H16V14H18V12Z" fill={color} />
    </svg>
  );
}
