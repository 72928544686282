import { ChevronLeft } from '@mui/icons-material';
import { Box, CardContent, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import moment from 'moment-timezone';
import { useState } from 'react';
import Icon from 'src/@core/components/icon';
import ProgramList from 'src/components/program-list';
import { useLanguage } from 'src/hooks';
import { DEFAULT_TIMEZONE } from '../../../utils/time';
import classes from './content.module.sass';

function Content({ projectUuid, programs, handleReturnProject }) {
  const { localizeText, localizeMessage, getLocalizationValue } = useLanguage();
  const [selectedDate, setSelectedDate] = useState(0);
  const [searchText, setSearchText] = useState('');

  const programByDate = programs.reduce((p, c) => {
    const date = moment.unix(c.dateStart).startOf('days').unix();
    return { ...p, [date]: [...(p[date] || []), c] };
  }, {});

  return (
    <Stack className={classes.content}>
      <Box position="relative" overflow="auto" height="100%">
        <Stack className={classes.header} gap="16px">
          <Stack direction="row" alignItems="center" justifyContent="space-between" flexWrap="wrap" gap="16px">
            <Stack>
              <Stack direction="row" gap="8px">
                <IconButton onClick={handleReturnProject}>
                  <ChevronLeft />
                </IconButton>
                <h2 className={classes.title}>{localizeText.BUSINESS_PROGRAM}: </h2>
              </Stack>
            </Stack>

            <Stack position="relative" sx={{ minWidth: { xs: '100%', md: '50%' }, maxWidth: '100vw' }}>
              <TextField
                size="small"
                value={searchText}
                onChange={(event) => setSearchText(event.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ color: 'text.primary' }}>
                      <Icon icon="mdi:magnify" />
                    </InputAdornment>
                  ),
                  endAdornment: searchText ? (
                    <InputAdornment position="end" onClick={() => setSearchText('')}>
                      <IconButton size="small">
                        <Icon icon="mdi:close" fontSize={20} />
                      </IconButton>
                    </InputAdornment>
                  ) : null,
                  sx: { borderRadius: '10px' },
                }}
              />
            </Stack>
          </Stack>
          <Stack direction="row" gap="8px" flexWrap="wrap">
            {Object.keys(programByDate).map((date, iDate) => (
              <div
                key={iDate}
                className={`${classes.date} ${iDate === selectedDate && classes.selected}`}
                onClick={() => setSelectedDate(iDate)}
              >
                {moment.tz(moment.unix(date), DEFAULT_TIMEZONE).format('DD MMMM')}
              </div>
            ))}
          </Stack>
        </Stack>

        {programs.length === 0 ? (
          <Typography sx={{ fontSize: 18, textAlign: 'center' }}>{localizeMessage.NO_DATA}</Typography>
        ) : (
          <ProgramList
            programs={(Object.values(programByDate)?.[selectedDate] ?? []).filter((program) =>
              searchText ? getLocalizationValue(program, 'name').includes(searchText) : true
            )}
            projectUuid={projectUuid}
          />
        )}
      </Box>
    </Stack>
  );
}

export default Content;
