import { yupResolver } from '@hookform/resolvers/yup';
import { ChevronRightOutlined, Visibility, VisibilityOff } from '@mui/icons-material';
import { Button, FormHelperText, IconButton, InputAdornment, Link, Stack, TextField } from '@mui/material';
import { SmartCaptcha } from '@yandex/smart-captcha';
import MuiPhoneNumber from 'material-ui-phone-number';
import qs from 'query-string';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { generalAPIs } from 'src/services';
import { request } from 'src/utils/request';
import { routes } from 'src/utils/routes';
import { AuthLayout } from '../../components';
import { registerAccount } from '../../store/actions/auth';
import { CAPTCHA_SITE_KEY, LOCALIZATION, MESSAGES, TEXT } from '../../utils/constant';
import { registrationValidationSchema } from '../../utils/formValidator';
import { createErrorNotification } from '../../utils/notifications';
import classes from './register.module.sass';

const RegisterPage = () => {
  const location = useLocation();
  const lang = useSelector((state) => state.app.lang) || LOCALIZATION.ru_RU;
  const transText = TEXT[lang];
  const [step, setStep] = useState(1);
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });
  const [token, setToken] = useState('');
  const [captchaError, setCaptchaError] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: { fullname: '', phone: '', email: '', password: '', confirmPassword: '' },
    resolver: yupResolver(registrationValidationSchema(step, lang)),
    mode: 'onTouched',
  });

  const queryParams = qs.parse(location.search);

  const fetchAppInfo = async () => {
    const response = await request(`/debug/app-info`);
    return response.message;
  };
  const { isLoading: isLoadingAppInfo, data: appInfo } = useQuery(['fetchAppInfo'], fetchAppInfo);
  const enableCaptcha = CAPTCHA_SITE_KEY && appInfo?.enableCaptcha;

  const onSubmit = async (values) => {
    if (step === 1) {
      console.log(values);
      setStep(2);
      return;
    }
    if (enableCaptcha && !token) {
      setCaptchaError(true);
      return;
    }
    registerAccount({
      ...values,
      captchaToken: token,
      invitationUuid: queryParams?.invitation_id,
      projectUuid: queryParams?.projectUuid,
      roleUuid: queryParams?.roleUuid,
    });
  };

  useEffect(() => {
    if (location.state && location.state.email) {
      createErrorNotification(MESSAGES[lang].ERROR_MESSAGE_NON_EXIST_EMAIL);
      setValue('email', location.state.email);
      location.state = null;
    }
    if (location.state && location.state.error) {
      createErrorNotification(location.state.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const labelStyle = { fontSize: 17, fontFamily: 'Lato' };
  const inputStyle = { fontSize: 20, fontFamily: 'Lato' };

  return (
    <AuthLayout>
      <div className={classes.root}>
        <span className={classes.title} color="initial">
          {step === 1 ? transText.REGISTRATION : transText.CREATE_PASSWORD}
        </span>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          {step === 1 ? (
            <>
              <TextField
                label={transText.FULLNAME}
                InputLabelProps={{
                  shrink: true,
                  style: labelStyle,
                }}
                InputProps={{
                  style: inputStyle,
                }}
                variant="standard"
                {...register('fullname')}
                error={errors.fullname ? true : false}
                helperText={errors.fullname?.message}
              />

              <MuiPhoneNumber
                label={transText.TELEPHONE_COMPANY}
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                  style: labelStyle,
                }}
                InputProps={{
                  style: inputStyle,
                }}
                name="phone"
                variant="standard"
                autoFormat={false}
                defaultCountry={'ru'}
                {...register('phone')}
                onChange={(value) => setValue('phone', value)}
                error={errors.phone ? true : false}
                helperText={errors.phone?.message}
              />

              <TextField
                label="E-mail"
                type="email"
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                  style: labelStyle,
                }}
                InputProps={{
                  style: inputStyle,
                }}
                variant="standard"
                {...register('email')}
                error={errors.email ? true : false}
                helperText={errors.email?.message}
              />
            </>
          ) : (
            <>
              <TextField
                label={transText.PASSWORD}
                type={showPassword.password ? 'text' : 'password'}
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                  style: labelStyle,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword({ ...showPassword, password: !showPassword.password })}
                      >
                        {showPassword.password ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                variant="standard"
                {...register('password')}
                error={errors.password ? true : false}
                helperText={errors.password?.message}
              />

              <TextField
                label={transText.REPEAT_PASSWORD}
                type={showPassword.confirmPassword ? 'text' : 'password'}
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                  style: labelStyle,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          setShowPassword({ ...showPassword, confirmPassword: !showPassword.confirmPassword })
                        }
                      >
                        {showPassword.confirmPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                variant="standard"
                {...register('confirmPassword')}
                error={errors.confirmPassword ? true : false}
                helperText={errors.confirmPassword?.message}
              />
              {enableCaptcha && !isLoadingAppInfo && (
                <Stack>
                  <SmartCaptcha
                    sitekey={CAPTCHA_SITE_KEY}
                    language={lang === LOCALIZATION.en_US ? 'en' : 'ru'}
                    onSuccess={(token) => {
                      setToken(token);
                      setCaptchaError(false);
                    }}
                    onTokenExpired={() => {
                      setToken('');
                    }}
                  />
                  {captchaError && <FormHelperText error={true}>{MESSAGES[lang].CAPTCHA_ERROR}</FormHelperText>}
                </Stack>
              )}
            </>
          )}

          <Button
            type="submit"
            variant="contained"
            size="large"
            className={classes.submitButton}
            endIcon={<>{step === 1 && <ChevronRightOutlined />} </>}
          >
            {step === 1 ? transText.PROCEED : transText.READY}
          </Button>

          <Link
            href={`${routes.login.path + location.search}`}
            underline="none"
            color="#000"
            style={{ textAlign: 'center', fontSize: '1.25rem' }}
          >
            {transText.ALREADY_HAVE_ACCOUNT}
          </Link>
        </form>
      </div>
    </AuthLayout>
  );
};

export default RegisterPage;
