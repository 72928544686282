import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import { createRef, useEffect, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { useSelector } from 'react-redux';

import 'cropperjs/dist/cropper.css';
import Cropper from 'react-cropper';

import ButtonLoading from 'src/components/button-loading';
import Loader from 'src/components/loader';
import { useDebounceEffect, useLanguage } from 'src/hooks';
import { UploadIcon } from 'src/icons';
import { EVENTS, IMAGE_FILE_TYPES } from 'src/utils/constant';
import eventBus from 'src/utils/eventBus';
import { createErrorNotification, createNotification } from 'src/utils/notifications';
import classes from './cropper.module.sass';

function ImageCropperModal() {
  const cropperRef = createRef();
  const { localizeMessage, localizeText } = useLanguage();

  const projectSettings = useSelector((state) => state.app.projectSettings);

  const avatarSize = projectSettings?.avatarSize;
  const avatarSizeWidth = avatarSize?.width ?? 100;
  const avatarSizeHeight = avatarSize?.height ?? 100;
  const aspectRatio = avatarSizeWidth / avatarSizeHeight;

  const [state, _setState] = useState({
    open: false,
    extraUpload: null,
    isResizable: true,
    onOk: async () => {},
  });
  const setState = (state) => _setState((old) => ({ ...old, ...state }));
  const [isUploading, setIsUploading] = useState(false);
  const [currentFile, setCurrentFile] = useState(null);
  const [isExtraUpload, setIsExtraUpload] = useState(false);
  const [rawFile, setRawFile] = useState(null);
  const [currentBlob, setCurrentBlob] = useState(null);

  const handleOpen = (stateData) => {
    setState({ ...stateData, open: true });
  };

  const handleClose = () => {
    setCurrentFile(null);
    setState({ open: false, extraUpload: null, onOk: async () => {} });
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (image) => resolve(reader.result);
      reader.onerror = reject;
    });

  function getBase64Size(base64) {
    return Math.ceil(base64.length * 0.73);
  }

  function resizeImage(base64) {
    return new Promise((resolve) => {
      const image = document.createElement('img');

      image.src = base64;

      image.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        const decreaser = 0.8;

        canvas.width = image.width * decreaser;
        canvas.height = image.height * decreaser;
        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

        resolve(canvas.toDataURL());
      };
    });
  }

  const handleChange = async (file) => {
    if (isUploading) return;
    try {
      setIsUploading(true);
      let base64 = await toBase64(file);
      if (!file.type.includes('pdf') && file.size / 1024 / 1024 >= 1) {
        while (getBase64Size(base64) > 1024 * 1024) {
          base64 = await resizeImage(base64);
        }
        setCurrentFile(base64);
        return;
      }
      if (file.type.includes('pdf') && file.size / 1024 / 1024 >= 5) {
        createErrorNotification(localizeMessage.FILE_SIZE_IS_TOO_LARGE);
        return;
      }

      setRawFile(file);

      const imageBlob = await fetch(base64).then((res) => res.blob());
      setCurrentBlob(imageBlob);

      const reader = new FileReader();
      reader.addEventListener('load', () => setCurrentFile(reader.result?.toString() || ''));
      reader.readAsDataURL(file);
    } catch (e) {
      console.error(e);
    } finally {
      setIsUploading(false);
    }
  };

  const handleSave = async () => {
    if (isUploading) return;
    try {
      setIsUploading(true);

      let base64 = currentFile;
      if (cropperRef.current) {
        base64 = cropperRef.current?.cropper.getCroppedCanvas().toDataURL();
      }
      const imageBlob = await fetch(base64).then((res) => res.blob());
      const file = new File([imageBlob], rawFile?.name ?? 'image', { type: rawFile.type });

      if (isExtraUpload) {
        await state.extraUpload.onOk(file);
      } else {
        await state.onOk(file);
      }
      createNotification(localizeMessage.SAVE_SUCCESSFULLY, 'success');
    } catch (error) {
      console.error(error);
    } finally {
      setIsUploading(false);
      handleClose();
    }
  };

  const handleChangeFile = () => {
    setCurrentFile(null);
  };

  useEffect(() => {
    eventBus.on(EVENTS.OPEN_IMAGE_CROPPER_MODAL, handleOpen);
    return () => {
      eventBus.off(EVENTS.OPEN_IMAGE_CROPPER_MODAL, handleOpen);
    };
  }, []);

  const SupportedTypes = state.isResizable ? IMAGE_FILE_TYPES : ['PDF', ...IMAGE_FILE_TYPES];
  const maxLimitMB = state.isResizable ? 1 : 5;

  if (!state.open) return null;
  return (
    <Dialog open={true} onClose={handleClose}>
      <DialogTitle>{localizeText.UPLOAD}</DialogTitle>
      <DialogContent dividers>
        <Stack sx={{ minWidth: { xs: '100%', md: '450px' } }}>
          <Stack>
            <Typography variant="body2">
              - {localizeText.ALLOWED}: {SupportedTypes.join(', ')}
            </Typography>
            <Typography variant="body2">
              - {localizeText.LIMIT_SIZE}: {maxLimitMB}MB
            </Typography>
          </Stack>
          {isUploading ? (
            <Loader />
          ) : (
            <Stack gap="8px" alignItems="center" sx={{ width: '100%', paddingX: '8px', label: { width: '100%' } }}>
              {currentFile ? (
                <>
                  <Button sx={{ width: { xs: '100%', md: '50%' } }} onClick={handleChangeFile}>
                    {localizeText.CHANGE}
                  </Button>
                  {state.isResizable ? (
                    <Cropper
                      ref={cropperRef}
                      src={currentFile}
                      aspectRatio={aspectRatio}
                      minCropBoxHeight={avatarSizeHeight}
                      minCropBoxWidth={avatarSizeWidth}
                      cropBoxResizable={false}
                      data={{ height: avatarSizeHeight, width: avatarSizeWidth }}
                    />
                  ) : (
                    <Stack sx={{ width: 'inherit' }}>
                      {rawFile?.type?.includes('pdf') ? (
                        <embed
                          src={currentBlob ? URL.createObjectURL(currentBlob) : ''}
                          style={{ minHeight: '60vh' }}
                        />
                      ) : (
                        <img alt="" src={currentFile} />
                      )}
                    </Stack>
                  )}
                </>
              ) : (
                <>
                  <FileUploader
                    types={SupportedTypes}
                    children={
                      <div className={classes.uploadZone}>
                        <div>
                          <UploadIcon />
                        </div>
                        <Typography color="#262626" align="center" variant="body1">
                          {localizeText.UPLOAD}
                        </Typography>
                      </div>
                    }
                    handleChange={(file) => {
                      setIsExtraUpload(false);
                      handleChange(file);
                    }}
                    name="file"
                  />
                  {state.extraUpload && (
                    <Button
                      type="button"
                      variant="text"
                      onClick={() => {
                        setIsExtraUpload(true);
                        handleChange(state.extraUpload.file);
                      }}
                    >
                      {state.extraUpload.text}
                    </Button>
                  )}
                </>
              )}
            </Stack>
          )}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ paddingTop: (theme) => `${theme.spacing(5)} !important` }}>
        <Button variant="outlined" color="secondary" onClick={handleClose}>
          {localizeText.CANCEL}
        </Button>
        <ButtonLoading variant="contained" disabled={isUploading || !currentFile} onClick={handleSave}>
          {localizeText.OK}
        </ButtonLoading>
      </DialogActions>
    </Dialog>
  );
}

export default ImageCropperModal;
