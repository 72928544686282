import { ChevronLeft } from '@mui/icons-material';
import { Box, Checkbox, CircularProgress, Grid, IconButton, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { shallowEqual, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getLocalizationValue } from 'src/utils/common';
import { routes } from 'src/utils/routes';
import { ChangePasswordForm, ConfirmModal, MainLayout, PackageDetailModal, ViewImageModal } from '../../components';
import AmountToggle from '../../components/amount-toggle';
import CheckOutForm from '../../components/check-out-form';
import { CompanyFormModal } from '../../components/company-form-modal';
import { addToOrder, countOrder, removeFromOrder } from '../../store/actions/app';
import { getUserProjects } from '../../store/actions/auth';
import { EVENTS, LOCALIZATION, TEXT } from '../../utils/constant';
import eventBus from '../../utils/eventBus';
import classes from './order.module.sass';

const OrderPage = () => {
  const navigate = useNavigate();
  const { projectUuid: id } = useParams();
  const lang = useSelector((state) => state.app.lang) || LOCALIZATION.ru_RU;

  const projects = useSelector((state) => state.auth.projects);
  const isLoadingProjects = useSelector((state) => state.auth.isLoadingProjects);
  const order = useSelector((state) => {
    const _order = (state.app.order || []).filter((item) =>
      state.auth.projects?.find((p) => p.uuid === item.projectUuid)
    );
    return id ? _order.filter((i) => i.projectUuid === id) : _order;
  }, shallowEqual);

  const [cart, setCart] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  console.log('Cart', cart);
  console.log('Order', order);
  const currentProject = projects.find((p) => p.uuid === id);
  const breadcrumbs = id
    ? [
      { path: '/', title: TEXT[lang].MAIN_PAGE },
      {
        path: routes.projectDetail.path.replace(':projectUuid', id),
        title: getLocalizationValue(currentProject?.name, lang, 'fullName') || currentProject?.name,
      },
      { path: null, title: TEXT[lang].BASKET },
    ]
    : [
      { path: '/', title: TEXT[lang].MAIN_PAGE },
      { path: null, title: TEXT[lang].BASKET },
    ];

  useEffect(() => {
    setCart([]);
    if (!isLoadingProjects) {
      getUserProjects();
    }
  }, []);

  const handleSelect = async (e, projectUuid, pack) => {
    const isChecked = e.target.checked;
    setIsLoading(true);
    if (isChecked) {
      const packages = order.find((item) => item.projectUuid === projectUuid)?.packages || [];
      setCart(cart.concat(packages.find((p) => p.package.id === pack.id)));
    } else {
      setCart(cart.filter((p) => p.package.id !== pack.id));
    }
    setIsLoading(false);
  };

  const handleAddToOrder = (projectUuid, p) => {
    addToOrder(projectUuid, p);
  };

  const handleRemoveFromOrder = (projectUuid, p) => {
    removeFromOrder(projectUuid, p);
  };

  const handleCount = (projectUuid, p, count) => {
    countOrder(projectUuid, p, count);
  };

  const handleShowDetail = (p) => {
    eventBus.emit(EVENTS.OPEN_PACKAGE_DETAIL_MODAL, {
      projectUuid: id,
      packages: [p.package],
    });
  };

  useEffect(() => {
    if (order?.length) {
      let allPackages = [];
      order.forEach((item) => (allPackages = allPackages.concat(item.packages)));
      setCart(allPackages);
    }
  }, [order]);

  return (
    <MainLayout breadcrumbs={breadcrumbs}>
      <Box className={classes.root}>
        <Stack direction="row" alignItems="center" gap="8px">
          <Box>
            <IconButton onClick={() => navigate(routes.projectDetail.path.replace(':projectUuid', id))}>
              <ChevronLeft />
            </IconButton>
          </Box>
          <h1 className={classes.title}>{TEXT[lang].BASKET}</h1>
        </Stack>
        {isLoadingProjects ? (
          <CircularProgress style={{ display: 'block', margin: 'auto', color: '#BC95F1' }} />
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={order.length ? 8 : 12}>
              <div
                className={classes.content}
                style={{
                  background: order.length ? 'white' : 'transparent',
                  boxShadow: !order.length && 'none',
                  padding: !order.length && '0',
                }}
              >
                {order.length ? (
                  <Stack alignContent="flex-start">
                    {order.map((item, index) => (
                      <React.Fragment key={index}>
                        {item.packages.map((p) => {
                          const _package = p.package;
                          const projectUuid = item.projectUuid;
                          const project = projects.find((project) => project.uuid === projectUuid);
                          const name = _package.localization?.[lang]?.name;
                          const projectLogo = project?.photos?.logo?.[0];

                          return (
                            project && (
                              <React.Fragment key={p.id}>
                                <Stack direction="row" className={classes.project}>
                                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Checkbox
                                      sx={{ height: 'fit-content', '&.Mui-checked': { color: '#BC95F1' } }}
                                      onChange={(e) => handleSelect(e, projectUuid, _package)}
                                      defaultChecked={cart.some((c) => _package.id === c.package.id)}
                                    ></Checkbox>
                                  </Box>
                                  <img className={classes.image} width="120" height="120" src={projectLogo} />

                                  <Stack width="100%" justifyContent="space-evenly">
                                    <Stack className={classes.top} direction="row" justifyContent="space-between">
                                      <div className={classes.name}>
                                        {name && !name.includes('null')
                                          ? name
                                          : _package.localization?.[LOCALIZATION.ru_RU]?.name || _package.name}
                                      </div>
                                      <div className={classes.price}>
                                        <NumberFormat
                                          value={_package.price?.['643']?.price * p.count}
                                          suffix=" руб"
                                          decimalScale={2}
                                          displayType="text"
                                          thousandSeparator=" "
                                          decimalSeparator=","
                                        />
                                      </div>
                                    </Stack>
                                    <Stack
                                      className={classes.top}
                                      direction="row"
                                      alignItems="center"
                                      justifyContent="space-between"
                                    >
                                      <Stack>
                                        <div className={classes.count}>
                                          {TEXT[lang].QUANTITY}: {p.count}
                                        </div>
                                        <br></br>
                                        <Typography
                                          textAlign="center"
                                          color="#BC95F1"
                                          sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}
                                          onClick={() => handleShowDetail(p)}
                                        >
                                          {TEXT[lang].SHOW_PACKAGE_DETAILS}
                                        </Typography>
                                      </Stack>
                                      <AmountToggle
                                        amount={p.count}
                                        onAdd={() => handleAddToOrder(projectUuid, _package)}
                                        onDistract={() => handleRemoveFromOrder(projectUuid, _package)}
                                        handleCount={(count) => handleCount(projectUuid, _package, count)}
                                        style={{ margin: 0 }}
                                      />
                                    </Stack>
                                  </Stack>
                                </Stack>

                                <Stack className={classes.projectMobile} alignItems="center">
                                  <Box sx={{ display: 'flex', alignItems: 'center' }}></Box>
                                  <img className={classes.image} width="120" height="120" src={projectLogo} />

                                  <div className={classes.name}>
                                    {name && !name.includes('null')
                                      ? name
                                      : _package.localization?.[LOCALIZATION.ru_RU]?.name || _package.name}
                                  </div>

                                  <Stack>
                                    <Stack
                                      className={classes.top}
                                      direction="row"
                                      alignItems="center"
                                      justifyContent="space-between"
                                    >
                                      <div className={classes.price}>
                                        <Checkbox
                                          sx={{
                                            paddingLeft: 0,
                                            height: 'fit-content',
                                            '&.Mui-checked': { color: '#BC95F1' },
                                            '& > svg': { width: 'inherit', height: 26 },
                                          }}
                                          onChange={(e) => handleSelect(e, projectUuid, _package)}
                                        ></Checkbox>
                                        <NumberFormat
                                          value={_package.price?.['643']?.price * p.count}
                                          suffix=" руб"
                                          decimalScale={2}
                                          displayType="text"
                                          thousandSeparator=" "
                                          decimalSeparator=","
                                        />
                                      </div>
                                    </Stack>
                                    <Stack
                                      className={classes.top}
                                      direction="column-reverse"
                                      alignItems="center"
                                      justifyContent="space-between"
                                      gap="8px"
                                    >
                                      <Stack alignItems="center" gap="8px">
                                        <div className={classes.count}>
                                          {TEXT[lang].QUANTITY}: {p.count}
                                        </div>
                                        <Typography
                                          textAlign="center"
                                          color="#BC95F1"
                                          sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}
                                          onClick={() => handleShowDetail(p)}
                                        >
                                          {TEXT[lang].SHOW_PACKAGE_DETAILS}
                                        </Typography>
                                      </Stack>
                                      <AmountToggle
                                        amount={p.count}
                                        onAdd={() => handleAddToOrder(projectUuid, _package)}
                                        onDistract={() => handleRemoveFromOrder(projectUuid, _package)}
                                        handleCount={(count) => handleCount(projectUuid, _package, count)}
                                        style={{ margin: 0 }}
                                      />
                                    </Stack>
                                  </Stack>
                                </Stack>
                              </React.Fragment>
                            )
                          );
                        })}
                      </React.Fragment>
                    ))}
                  </Stack>
                ) : (
                  <p style={{ fontSize: 20, textAlign: 'center' }}>{TEXT[lang].EMPTY_BASKET}</p>
                )}
              </div>
            </Grid>
            {Boolean(order.length) && (
              <Grid item xs={12} sm={12} md={12} lg={4}>
                <div className={classes.content}>
                  <CheckOutForm isLoading={isLoading} cart={cart} projectUuid={id} />
                </div>
              </Grid>
            )}
          </Grid>
        )}
      </Box>
      <ConfirmModal />
      <ViewImageModal />
      <ChangePasswordForm />
      <PackageDetailModal />
      <CompanyFormModal />
    </MainLayout>
  );
};

export default OrderPage;
