import useMediaQuery from '@mui/material/useMediaQuery';

// ** Layout Imports
// !Do not remove this Layout import
import Layout from 'src/@core/layouts/Layout';

// ** Navigation Imports
import VerticalNavItems from 'src/navigation/vertical';
import VerticalAppBarContent from './components/vertical/AppBarContent';

// ** Hook Import
import { useSettings } from 'src/@core/hooks/useSettings';
import { LeftMenu } from 'src/components/main-layout/components/left-menu';

const UserLayout = ({ children, contentHeightFixed, extraNavItems = [], removeExactProjectsPath, hideSidebar }) => {
  // ** Hooks
  const { settings, saveSettings } = useSettings();
  const hidden = useMediaQuery((theme) => theme.breakpoints.down('lg'));

  return (
    <Layout
      hidden={hidden}
      hideSidebar={hideSidebar}
      settings={settings}
      saveSettings={saveSettings}
      contentHeightFixed={contentHeightFixed}
      verticalLayoutProps={{
        navMenu: {
          navItems: VerticalNavItems(removeExactProjectsPath).concat(extraNavItems),
        },
        appBar: {
          content: (props) => (
            <VerticalAppBarContent
              hidden={hidden}
              hideSidebar={hideSidebar}
              settings={settings}
              saveSettings={saveSettings}
              toggleNavVisibility={props.toggleNavVisibility}
            />
          ),
        },
      }}
    >
      {children}
    </Layout>
  );
};

export default UserLayout;
