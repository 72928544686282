import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const FooterContent = () => {
  return (
    <Box
      sx={{
        display: { xs: 'flex', md: 'none' },
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: { xs: 'center', md: 'space-between' },
      }}
    >
      <Typography
        sx={{
          fontWeight: 400,
          fontSize: '12px',
          lineHeight: '24px',
          // color: 'rgba(58, 53, 65, 0.6)',
        }}
      >{`Copyright © ${new Date().getFullYear()} ExpoForum`}</Typography>
    </Box>
  );
};

export default FooterContent;
