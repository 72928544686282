import classes from './amount-toggle.module.sass';

export default function AmountToggle({ amount, onAdd, onDistract, handleCount, style }) {
  return (
    <div className={classes.root} style={style}>
      <div className={classes.minus} onClick={onDistract}>
        -
      </div>
      <input
        className={classes.amount}
        onChange={(event) => handleCount(event.target.value)}
        value={amount}
        min="0"
        type="number"
      />
      <div className={classes.plus} onClick={onAdd}>
        +
      </div>
    </div>
  );
}
