import {
  FormControl,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { setLang } from '../../store/actions/app';
import { LANGUAGES, LOCALIZATION, TEXT } from '../../utils/constant';

function LanguageSelect({ sx }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const lang = useSelector((state) => state.app.lang) || LOCALIZATION.ru_RU;
  const open = Boolean(anchorEl);

  const handleChange = (lang) => {
    setLang(lang);
    handleClose();
  };

  const handleClick = (event) => {
    if (anchorEl) {
      handleClose();
    } else {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <ListItem sx={sx}>
      <ListItemIcon sx={{ minWidth: '32px !important' }}>
        <img
          width="20"
          height="20"
          src={LANGUAGES.find((l) => l.lang === lang)?.flag}
          alt="flag"
          style={{
            width: '20px',
            height: '20px',
          }}
        />
      </ListItemIcon>
      <Stack direction="row" width="100%" justifyContent="space-between" alignItems="center">
        <ListItemText
          primary={LANGUAGES.find((l) => l.lang === lang).name}
          primaryTypographyProps={{ sx: { color: 'white' } }}
        ></ListItemText>

        <Menu
          id="composition-button"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {LANGUAGES.map((language, index) => {
            return (
              <MenuItem key={index} onClick={() => handleChange(language.lang)}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <img
                    width="15"
                    height="15"
                    src={language.flag}
                    alt="flag"
                    style={{
                      width: '15px',
                      height: '15px',
                    }}
                  />
                  <span>{language.name}</span>
                </Stack>
              </MenuItem>
            );
          })}
        </Menu>
        <Typography
          id="composition-button"
          aria-controls={open ? 'composition-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          sx={{ cursor: 'pointer', textDecoration: 'underline', color: 'white' }}
          onClick={handleClick}
        >
          {TEXT[lang].CHANGE_LANGUAGE_BUTTON}
        </Typography>
      </Stack>
    </ListItem>
  );
}

export const LanguageMuiSelect = () => {
  const lang = useSelector((state) => state.app.lang) || LOCALIZATION.ru_RU;
  const handleChange = (e) => setLang(e.target.value);

  return (
    <FormControl>
      <Select
        value={lang || LOCALIZATION.ru_RU}
        size="small"
        onChange={handleChange}
        displayEmpty
        renderValue={(value) => {
          const langConfig = LANGUAGES.find((l) => l.lang === value);
          return (
            <Stack direction="row" spacing={1} alignItems="center">
              <img
                width="15"
                height="15"
                src={langConfig?.flag}
                alt="flag"
                style={{
                  width: '15px',
                  height: '15px',
                }}
              />
              <Typography variant="body1">{langConfig?.name}</Typography>
            </Stack>
          );
        }}
      >
        {LANGUAGES.map((language, index) => {
          return (
            <MenuItem value={language.lang} key={index}>
              <Stack direction="row" spacing={1} alignItems="center">
                <img
                  width="15"
                  height="15"
                  src={language.flag}
                  alt="flag"
                  style={{
                    width: '15px',
                    height: '15px',
                  }}
                />
                <span>{language.name}</span>
              </Stack>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default LanguageSelect;
