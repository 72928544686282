export const SecurityIcon = ({ color = 'white' }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_271_7965)">
        <path
          d="M10.008 19.2C8.91535 18.675 7.92674 18.1188 7.04218 17.5312C6.15763 16.9438 5.38365 16.3813 4.72023 15.8438C4.05682 15.3063 3.4747 14.6688 2.97389 13.9313C2.47308 13.1938 2.05682 12.5531 1.72511 12.0094C1.3934 11.4656 1.11698 10.75 0.895841 9.8625C0.674702 8.975 0.505597 8.24688 0.388524 7.67813C0.27145 7.10938 0.183645 6.29375 0.125109 5.23125C0.0665722 4.16875 0.0340519 3.3375 0.0275478 2.7375C0.0210438 2.1375 0.0177917 1.225 0.0177917 0C0.0177917 0.325 0.505597 0.60625 1.48121 0.84375C2.45682 1.08125 3.63405 1.2 5.01291 1.2C6.39178 1.2 7.56901 1.08125 8.54462 0.84375C9.52023 0.60625 10.008 0.325 10.008 0C10.008 0.325 10.4958 0.60625 11.4715 0.84375C12.4471 1.08125 13.6243 1.2 15.0032 1.2C16.382 1.2 17.5593 1.08125 18.5349 0.84375C19.5105 0.60625 19.9983 0.325 19.9983 0C19.9983 1.225 19.995 2.1375 19.9885 2.7375C19.982 3.3375 19.9495 4.16875 19.891 5.23125C19.8324 6.29375 19.7446 7.10938 19.6276 7.67813C19.5105 8.24688 19.3414 8.975 19.1202 9.8625C18.8991 10.75 18.6227 11.4656 18.291 12.0094C17.9593 12.5531 17.543 13.1938 17.0422 13.9313C16.5414 14.6688 15.9593 15.3063 15.2958 15.8438C14.6324 16.3813 13.8584 16.9438 12.9739 17.5312C12.0893 18.1188 11.1007 18.675 10.008 19.2ZM10.008 2.4C10.008 2.725 9.64056 3.00625 8.9056 3.24375C8.17064 3.48125 7.28934 3.6 6.2617 3.6C5.55926 3.6 4.86332 3.54375 4.17389 3.43125C3.48446 3.31875 2.93161 3.16875 2.51535 2.98125C2.51535 3.96875 2.52836 4.7625 2.55438 5.3625C2.58039 5.9625 2.64218 6.67813 2.73974 7.50938C2.8373 8.34063 2.98365 9.025 3.17877 9.5625C3.37389 10.1 3.65356 10.7063 4.01779 11.3813C4.38202 12.0563 4.82755 12.6438 5.35438 13.1438C5.88121 13.6438 6.53487 14.1625 7.31535 14.7C8.09584 15.2375 8.9934 15.7375 10.008 16.2V2.4Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_271_7965">
          <rect width="20" height="19.2" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};
