import React from 'react';
import { Grid, IconButton } from '@mui/material';
import classes from './footer.module.sass';
// import { FacebookIcon, TelegramIcon, TwitterIcon } from '../../../../icons';

export function Footer() {
  return (
    <Grid
      container
      spacing={0}
      direction="row"
      justifyContent="left"
      alignItems="center"
      alignContent="stretch"
      className={classes.root}
    >
      <Grid item xs={12} sm={6} className={classes.copyRight}>
        Copyright © 2022 ExpoForum
      </Grid>
      {/* <Grid item xs={12} sm={6} className={classes.socialIcon}>
        <IconButton>
          <FacebookIcon color="#949598" />
        </IconButton>
        <IconButton>
          <TelegramIcon color="#949598" />
        </IconButton>
        <IconButton>
          <TwitterIcon color="#949598" />
        </IconButton>
      </Grid> */}
    </Grid>
  );
}
