import React from 'react';
import { Container, Grid } from '@mui/material';
import classes from './auth-layout.module.sass';
import { Footer, Header } from './components';
import { BREAKPOINTS } from '../../utils/constant';
import { useBreakpoint } from '../../hooks';
import { LanguageMuiSelect } from '../language-select';

export function AuthLayout({ children }) {
  const { windowSize } = useBreakpoint();

  return (
    <Container disableGutters maxWidth={false} className={classes.root}>
      <Grid
        container
        spacing={0}
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        alignContent="stretch"
        wrap="nowrap"
        className={classes.container}
      >
        <Grid
          item
          sm={12}
          md={6}
          className={classes.left}
          style={{ backgroundImage: windowSize.width < BREAKPOINTS.md && `url(/images/auth-background.png)` }}
        >
          <Header />
          {children}
          <Footer />
        </Grid>
        <Grid
          item
          md={6}
          justifyContent="center"
          alignItems="center"
          className={classes.right}
          style={{ display: 'flex', backgroundImage: `url(/images/auth-background.png)` }}
        >
          <div className={classes.language}>
            <LanguageMuiSelect />
          </div>
          <img className={classes.image} src="/images/auth-icon.png" alt="Icon" />
        </Grid>
      </Grid>
    </Container>
  );
}
