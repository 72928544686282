import { ChevronLeft, Delete, Download, Share } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Loader from 'src/components/loader';
import MenuPopover from 'src/components/menu-popover';
import { routes } from 'src/utils/routes';
import QRCodeModal from '../../../components/qr-code-modal';
import VizitStatus from '../../../components/status/VizitStatus';
import { UploadIcon } from '../../../icons';
import { generalAPIs, userAPIs } from '../../../services';
import { EVENTS, IMAGE_FILE_TYPES, LOCALIZATION, MESSAGES, TEXT } from '../../../utils/constant';
import eventBus from '../../../utils/eventBus';
import { createErrorNotification, createNotification } from '../../../utils/notifications';
import classes from './business-card.module.sass';
import CompanyForm from './company-form';
import Members from './members';

export const getTimeSlotOptions = (startHour = 10, endHour = 21) => {
  const startTime = moment().set('hour', startHour).set('minute', 0);
  const endTime = moment().set('hour', endHour).set('minute', 0);
  return Array.from({ length: endTime.diff(startTime, 'hours') * 3 }, (_, i) => {
    const start = startTime
      .clone()
      .add(i / 3, 'hour')
      .format('HH:mm');
    const end = startTime
      .clone()
      .add(i / 3, 'hour')
      .add(20, 'minute')
      .format('HH:mm');
    return `${start} - ${end}`;
  });
};

export const getTableOptions = () => Array.from({ length: 30 }, (_, i) => i + 1);

function BusinessCardPage({ isLoading, projectUuid, vizits, setVizits, setTab, membersVizits, setMembersVizits }) {
  const navigate = useNavigate();
  const lang = useSelector((state) => state.app.lang) || LOCALIZATION.ru_RU;
  const [isUploading, setIsUploading] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [uploadedLogo, setUploadedLogo] = useState(null);
  const [requireLogo, setRequireLogo] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const fetchShareLink = async ({ queryKey }) => {
    const [_, vizits] = queryKey;
    const response = await userAPIs.getVizitShareLink(vizits?.uuid);
    return response.message;
  };

  const { data: shareLink } = useQuery(['fetchShareLink', vizits], fetchShareLink, { enabled: !!vizits?.uuid });

  const handleChange = async (file) => {
    if (isUploading) return;
    try {
      setIsUploading(true);
      setRequireLogo(false);
      if (!vizits) {
        setUploadedLogo(file);
      } else {
        const formData = new FormData();
        formData.append('fileDocument', file, file.name);
        const response = await userAPIs.updateLogoVizit(projectUuid, formData);
        setVizits(response.message);
        createNotification(MESSAGES[lang].SAVE_SUCCESSFULLY);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsUploading(false);
    }
  };

  const deleteUserVizit = async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    try {
      await userAPIs.deleteUserVizit(projectUuid);
      setVizits(null);
      createNotification(MESSAGES[lang].DELETE_SUCCESSFULLY);
    } catch (e) {
      console.log(e);
      createErrorNotification(MESSAGES[lang].ERROR);
    } finally {
      setTimeout(() => setIsSubmitting(false), 200);
    }
  };

  const handleDeleteUserVizit = () => {
    eventBus.emit(EVENTS.OPEN_CONFIRM_MODAL, {
      onOk: () => {
        deleteUserVizit();
      },
    });
  };

  const handleDisplayShareLink = () => {
    eventBus.emit(EVENTS.OPEN_QR_CODE_MODAL, shareLink);
  };

  if (isLoading) return <Loader />;
  return (
    <Card>
      <CardContent className={classes.content}>
        <QRCodeModal />
        <div className={classes.header}>
          <IconButton onClick={() => setTab(null)}>
            <ChevronLeft />
          </IconButton>
          <h2 className={classes.title}>{TEXT[lang].MY_BUSINESS_CARD}</h2>
          {vizits && <VizitStatus status={vizits.status} />}
        </div>
        {!vizits && (
          <Alert severity="warning" sx={{ margin: '0 auto 10px', width: 'fit-content' }}>
            {MESSAGES[lang].WARNING_CREATE_VIZIT}
          </Alert>
        )}

        <Grid container position="relative" spacing={{ xs: 4, md: 8 }} className={classes.userList}>
          {vizits && (
            <Grid
              item
              xs={12}
              sx={{
                position: { xs: 'relative', md: 'absolute' },
                right: 0,
                top: 0,
                zIndex: 1,
                padding: { xs: 'auto', md: '0px !important' },
              }}
            >
              <Stack direction="row" justifyContent="flex-end" gap="8px">
                {/* <Button variant="outlined" style={{ 'textTransform': 'none' }} startIcon={<Download />} onClick={handleDownloadVCard}>
                {MESSAGES[lang].DOWNLOAD_VCARD}
              </Button> */}
                {/* {vizits.status !== 'approved' && (
                <Button
                  onClick={() => navigate(routes.projectBuyTicket.path.replace(':projectUuid', projectUuid))}
                  variant="outlined"
                  style={{ textTransform: 'none', borderRadius: '4px' }}
                >
                  {TEXT[lang].BUY_NETWORKING_PACKAGE}
                </Button>
              )} */}
                <MenuPopover
                  MenuElements={
                    <>
                      <Button
                        onClick={handleDisplayShareLink}
                        variant="outlined"
                        sx={
                          {
                            // borderColor: (theme) => theme.palette.grey[500],
                            // color: (theme) => theme.palette.grey[500],
                          }
                        }
                        startIcon={<Share />}
                        style={{ textTransform: 'none', borderRadius: '4px' }}
                      >
                        {MESSAGES[lang].SHARE_MY_LINK}
                      </Button>
                      <Button
                        aria-label="close"
                        onClick={handleDeleteUserVizit}
                        color="error"
                        sx={{
                          border: '1px solid',
                          // borderColor: (theme) => theme.palette.grey[500],
                          // color: (theme) => theme.palette.grey[500],
                          borderRadius: '4px',
                        }}
                        startIcon={<Delete />}
                      >
                        {MESSAGES[lang].DELETE}
                      </Button>
                    </>
                  }
                />
              </Stack>
            </Grid>
          )}
          {vizits && (
            <>
              <Grid item xs={12} lg={12}>
                {isUploading ? (
                  <CircularProgress style={{ display: 'block', margin: '0 auto', color: '#BC95F1' }} />
                ) : vizits?.logo || uploadedLogo ? (
                  <Stack className={classes.imageWrapper}>
                    <input
                      // ref={inputFileRef}
                      accept="image/jpg, image/jpeg, image/png"
                      className={classes.inputAvatar}
                      id="logo-file"
                      multiple={false}
                      type="file"
                      onChange={(e) => handleChange(e.target?.files?.[0])}
                    />

                    <label htmlFor="logo-file">
                      {/* <CameraAltIcon /> */}
                      <img
                        src={
                          vizits?.logo
                            ? vizits?.logo + `?dummy=${new Date().getTime()}`
                            : URL.createObjectURL(uploadedLogo)
                        }
                        className={classes.image}
                        alt="logo"
                      />
                    </label>
                  </Stack>
                ) : (
                  <FileUploader
                    types={IMAGE_FILE_TYPES}
                    children={
                      <div className={classes.uploadZone}>
                        <div>
                          <UploadIcon />
                        </div>
                        <Typography color="#262626" align="center" fontStyle={{ fontSize: '1.2rem' }}>
                          {TEXT[lang].UPLOAD_LOGO}
                        </Typography>
                        <Typography color="#8A8A8E">{TEXT[lang].FILE_NOT_MORE_15MB}</Typography>
                      </div>
                    }
                    handleChange={handleChange}
                    name="file"
                  />
                )}
                {requireLogo && !uploadedLogo && !vizits && (
                  <p style={{ color: 'red' }}>* {MESSAGES[lang].PLEASE_UPLOAD_LOGO}</p>
                )}
              </Grid>
            </>
          )}
          <Grid item xs={12} lg={12} sx={{ margin: 'auto' }}>
            {!isSubmitting && (
              <CompanyForm
                projectUuid={projectUuid}
                vizits={vizits}
                setVizits={setVizits}
                uploadedLogo={uploadedLogo}
                setRequireLogo={setRequireLogo}
                setMembersVizits={setMembersVizits}
              />
            )}
          </Grid>
        </Grid>

        {vizits && (
          <>
            <Divider sx={{ margin: '2rem 0' }} />
            <div>
              <Stack direction="row" style={{ alignItems: 'center', marginBottom: '2rem', gap: '16px' }}>
                <h2 className={classes.title} style={{ fontWeight: 500, margin: 0 }}>
                  {TEXT[lang].MEMBERS}
                </h2>
                {(membersVizits || []).length === 0 && (
                  <div className={classes.button} style={{ width: 'fit-content', margin: 0 }}>
                    <Button
                      className={classes.secondaryButton}
                      variant="contained"
                      size="large"
                      //  endIcon={<Add />}
                      //  onClick={() => setShowAdd(true)}
                      // disabled={isSubmitting || !isValidForm || !uploadedFile}
                      onClick={() => navigate(routes.projectAccreadition.path.replace(':projectUuid', projectUuid))}
                    >
                      {TEXT[lang].ADD_MEMBER_IN_TAB_ACCREDAITION}
                    </Button>
                  </div>
                )}
              </Stack>
              <Grid container spacing={4}>
                {showAdd && (
                  <Members
                    projectUuid={projectUuid}
                    membersVizits={membersVizits}
                    setMembersVizits={setMembersVizits}
                    setShowAdd={setShowAdd}
                  />
                )}
                {(membersVizits || []).map((member, i) => (
                  <Members
                    key={i}
                    member={member}
                    projectUuid={projectUuid}
                    membersVizits={membersVizits}
                    setMembersVizits={setMembersVizits}
                  />
                ))}
              </Grid>
            </div>
          </>
        )}
      </CardContent>
    </Card>
  );
}

export default BusinessCardPage;
