import { Button, TextField } from '@mui/material';
import { useState } from 'react';
import { AuthLayout, ForgotPasswordForm } from '../../components';
import classes from './forgot-password.module.sass';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { authAPIs } from '../../services';
import { LOCALIZATION, MESSAGES, TEXT } from '../../utils/constant';
import { loginWithoutPasswordValidationSchema } from '../../utils/formValidator';
import { createErrorNotification } from '../../utils/notifications';
import { useSelector } from 'react-redux';
import { getTransMessages } from '../../utils/common';

const ForgotPasswordPage = () => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const lang = useSelector((state) => state.app.lang) || LOCALIZATION.ru_RU;
  const transText = TEXT[lang];

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { email: '' },
    resolver: yupResolver(loginWithoutPasswordValidationSchema(lang)),
  });

  const onSubmit = async ({ email }) => {
    const transMessages = getTransMessages();
    try {
      setIsSubmitting(true);
      await authAPIs.forgotPassword({ email });
      navigate('/login', { state: { message: MESSAGES[lang].MESSAGE_SENT_PASSWORD_TO_EMAIL } });
    } catch (e) {
      console.log(e);
      if (e?.message?.error?.includes('not found')) {
        navigate('/register', { state: { error: MESSAGES[lang].ERROR_MESSAGE_FORGOT_PASSWORD_EMAIL } });
      } else {
        createErrorNotification(transMessages.ERROR);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const labelStyle = { fontSize: 17, fontFamily: 'Lato' };
  const inputStyle = { fontSize: 20, fontFamily: 'Lato' };
  return (
    <AuthLayout>
      <div className={classes.root}>
        <span className={classes.title} color="initial">
          {transText.RESET_PASSWORD}
        </span>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <TextField
            label="E-mail"
            InputLabelProps={{
              shrink: true,
              style: labelStyle,
            }}
            InputProps={{
              style: inputStyle,
            }}
            autoFocus
            variant="standard"
            {...register('email')}
            error={errors.email ? true : false}
            helperText={errors.email?.message}
          />

          <Button
            type="submit"
            variant="contained"
            size="large"
            className={classes.submitButton}
            disabled={isSubmitting}
          >
            OK
          </Button>

          {/* <Link href="/register" underline="none" color="#000" style={{ textAlign: 'center', fontSize: '1.25rem' }}>
            У меня ещё нет аккаунта
          </Link> */}
        </form>

        <ForgotPasswordForm />
      </div>
    </AuthLayout>
  );
};

export default ForgotPasswordPage;
