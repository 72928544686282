import { CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getMyTicket } from '../../store/actions/app';

function MyTicketPage() {
  const params = useParams();
  const [source, setSource] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async (userUuid, projectUuid) => {
    try {
      const src = await getMyTicket(userUuid, projectUuid);
      setSource(src);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const { userUuid, projectUuid } = params;
    if (userUuid && projectUuid) {
      fetchData(userUuid, projectUuid);
    }
  }, [params]);

  return isLoading ? (
    <CircularProgress style={{ display: 'block', margin: '20vh auto 0', color: '#BC95F1' }} />
  ) : (
    <object data={source} style={{ height: '100vh', width: '100%' }} type="application/pdf" />
  );
}

export default MyTicketPage;
