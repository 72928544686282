import * as constants from '../constants/auth';
import * as appConstants from '../constants/app';
import { dispatch, getCurrentState } from '../index';
import { authAPIs, projectsAPIs, userAPIs } from '../../services';
import { createNotification, createErrorNotification } from '../../utils/notifications';
import { getProjectsList, getRolesList } from './app';
import { getPartsOfFullName, getTransMessages, uniqueArrayBase } from '../../utils/common';
import qs from 'query-string';
import moment from 'moment-timezone';

export const login = async ({ email, password, projectUuid, roleUuid }) => {
  const transMessages = getTransMessages();
  try {
    const response = await authAPIs.login({ login: email, password, projectUuid, roleUuid });
    const { accessToken, refreshToken } = response.message || {};
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
    getCurrentUser();
    createNotification(transMessages.AUTHORIZATION_SUCCESSFULLY);
    return true;
  } catch (error) {
    dispatch({ type: constants.AUTH__ERROR, status: true });
    createErrorNotification(transMessages.EMAIL_OR_PASSWORD_INCORRECT);
    return error;
  }
};

export const logout = () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  dispatch({ type: appConstants.RESET_STATE });
  dispatch({ type: constants.AUTH__LOGOUT });
};

export const getCurrentUserProfile = async () => {
  try {
    const response = await userAPIs.getCurrentUser();
    const user = response.message || {};
    dispatch({ type: constants.AUTH__UPDATE_USER, user });
    return user;
  } catch (error) {
    console.log(error);
  }
};

export const getCurrentUser = async () => {
  try {
    const response = await userAPIs.getCurrentUser();
    const user = response.message || {};
    dispatch({ type: constants.AUTH__LOGIN_SUCCESS, user });
    getAvatarUser();
    getCurrentUserCompany();
    getProjectsList(
      qs.stringify({
        plan_since: moment().startOf('d').unix(),
        plan_till: moment().startOf('d').unix() * 100,
        isArchived: false
      })
    );
    getRolesList();
    return user;
  } catch (error) {
    console.log(error);
    dispatch({ type: constants.AUTH__LOGOUT });
  }
};

export const getAvatarUser = async () => {
  try {
    dispatch({ type: constants.AUTH__SET_LOADING_AVATAR, loading: true });
    const avatarBlob = await userAPIs.getAvatarCurrentUser();
    const avatar = window.URL.createObjectURL(avatarBlob);
    dispatch({ type: constants.AUTH__SET_AVATAR, avatar });
    return avatar;
  } catch (error) {
    console.log(error);
  } finally {
    dispatch({ type: constants.AUTH__SET_LOADING_AVATAR, loading: false });
  }
};

export const uploadAvatar = async (avatar) => {
  try {
    dispatch({ type: constants.AUTH__SET_LOADING_AVATAR, loading: true });
    const formData = new FormData();
    formData.append('fileDocument', avatar, 'passport.png');
    const newAvatar = await userAPIs.uploadAvatar(formData);
    dispatch({ type: constants.AUTH__SET_AVATAR, avatar: window.URL.createObjectURL(avatar) });
    return newAvatar;
  } catch (error) {
    return null;
  } finally {
    dispatch({ type: constants.AUTH__SET_LOADING_AVATAR, loading: false });
  }
};

export const registerAccount = async (
  { fullname, phone, email, password, invitationUuid, projectUuid, roleUuid, captchaToken },
  setNewAccount = true
) => {
  const transMessages = getTransMessages();
  try {
    const { lastName, firstName, middleName } = getPartsOfFullName(fullname);
    const countryResidence = 0;
    const citizenship = 0;
    const response = await authAPIs.register({
      firstName,
      middleName,
      lastName,
      phone,
      email,
      password,
      countryResidence,
      citizenship,
      projectUuid,
      roleUuid,
      token: captchaToken,
      ...(invitationUuid ? { invitationUuid } : {}),
    });
    await login({ email: email ? email : response.message, password });
    if (setNewAccount) {
      updateIsNewAccount({ isNewAccount: true, newProjectUuid: projectUuid });
    }
    return true;
  } catch (error) {
    createErrorNotification(transMessages.EMAIL_EXIST);
    return false;
  }
};

export const updateIsNewAccount = ({ isNewAccount, newProjectUuid }) => {
  dispatch({
    type: constants.AUTH__SET_IS_NEW_ACCOUNT,
    isNewAccount: isNewAccount,
    newProjectUuid: newProjectUuid,
  });
};

export const updateUser = async (
  { fullname, city, phone, gender, citizenship, birthday, readyToAccreditate, forceEdit },
  showNotification = true
) => {
  const transMessages = getTransMessages();
  try {
    const { lastName, firstName, middleName } = getPartsOfFullName(fullname);
    await userAPIs.updateCurrentUser({
      firstName,
      middleName,
      lastName,
      phone,
      city,
      gender,
      citizenship,
      birthday,
      readyToAccreditate,
      forceEdit,
    });
    const newProfile = await getCurrentUser();
    showNotification && createNotification(transMessages.SAVE_SUCCESSFULLY);
    return newProfile;
  } catch (error) {
    console.log(error);
    showNotification && createErrorNotification(transMessages.WRONG_DATA);
    return null;
  }
};

export const getCurrentUserPassportData = async (fetchPassportFile = true) => {
  try {
    const response = await userAPIs.getCurrentUserPassportData();
    const passport = response.message || {};
    dispatch({ type: constants.AUTH__SET_PASSPORT, passport });
    if (fetchPassportFile) {
      const passportFile = await userAPIs.getCurrentUserPassportFile();
      return passportFile;
    } else {
      countScanning();
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const countScanning = (isReset) => {
  const scanningCount = getCurrentState().auth.scanningCount || 0;
  dispatch({
    type: constants.AUTH__SET_SCANNING_COUNT,
    scanningCount: isReset ? 0 : scanningCount + 1,
  });
};

export const uploadPassportFile = async (file) => {
  try {
    const formData = new FormData();
    formData.append('fileDocument', file, file.name);
    const response = await userAPIs.uploadPassportFile(formData);
    return response;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const updatePassportData = async (
  { nationality, document_number, birth_date, issued_by },
  showNotification = true
) => {
  const transMessages = getTransMessages();
  try {
    const response = await userAPIs.updatePassportData({
      nationality,
      document_number,
      birth_date,
      issued_by,
    });
    dispatch({ type: constants.AUTH__SET_PASSPORT, passport: response.message });
    showNotification && createNotification(transMessages.SAVE_SUCCESSFULLY);
  } catch (error) {
    return false;
  }
};

export const deletePassportFile = async () => {
  try {
    await userAPIs.deletePassportFile();
    dispatch({ type: constants.AUTH__SET_PASSPORT, passport: null });
  } catch (error) { }
};

export const getUserProjects = async () => {
  try {
    dispatch({ type: constants.AUTH__SET_LOADING, dataType: 'isLoadingProjects', loading: true });
    const response = await userAPIs.getUserProjects(
      qs.stringify({
        plan_since: moment().startOf('d').unix(),
        plan_till: moment().startOf('d').unix() * 100,
        isArchived: false
      })
    );
    const projects = response.message || [];
    dispatch({ type: constants.AUTH__SET_PROJECTS, projects });
    return projects;
  } catch (error) {
  } finally {
    dispatch({ type: constants.AUTH__SET_LOADING, dataType: 'isLoadingProjects', loading: false });
  }
};

export const getCurrentUserCompany = async () => {
  try {
    const response = await userAPIs.getUserCompany();
    const company = response.message?.[0];
    dispatch({ type: constants.AUTH__SET_COMPANY, company });
    return company;
  } catch (error) {
    return null;
  }
};

export const deleteUserCompany = async () => {
  const transMessages = getTransMessages();
  try {
    await userAPIs.deleteUserCompany();
    dispatch({ type: constants.AUTH__SET_COMPANY, company: null });
    createNotification(transMessages.DELETE_SUCCESSFULLY);
  } catch (error) {
    createErrorNotification(transMessages.ERROR);
    return null;
  }
};

export const addCompany = async (body) => {
  try {
    const transMessages = getTransMessages();
    await userAPIs.addCompany(body);
    await getCurrentUserCompany();
    createNotification(transMessages.COMPANY_ADDED);
  } catch (error) {
    return null;
  }
};

export const getMyOrder = async (projectUuid) => {
  try {
    const response = await userAPIs.getUserOrders(projectUuid);
    const myOrders = response.message || [];
    const projectUuids = uniqueArrayBase(myOrders.map((i) => i.projectUuid));
    const projectResponses = await Promise.all(projectUuids.map((id) => projectsAPIs.getProjects(`uuid=${id}`)));
    let projects = [];
    projectResponses.forEach((i) => {
      projects = projects.concat(i.message || []);
    });
    dispatch({ type: constants.AUTH__SET_MY_ORDERS, myOrders });
    return { myOrders, projects };
  } catch (error) {
    dispatch({ type: constants.AUTH__SET_MY_ORDERS, myOrders: [] });
    return [];
  }
};

export const createMyOrder = async (body) => {
  try {
    const response = await userAPIs.createUserOrder(body);
    return response.message;
  } catch (error) {
    return null;
  }
};

export const deleteOrder = async (orderUuid) => {
  try {
    await userAPIs.deleteUserOrder(orderUuid);
    const oldOrders = getCurrentState().auth.myOrders;
    const newOrders = oldOrders.filter((order) => order.id !== orderUuid);
    dispatch({ type: constants.AUTH__SET_MY_ORDERS, myOrders: newOrders });
    return true;
  } catch (error) {
    return false;
  }
};

export const getArchivedOrder = async () => {
  try {
    const response = await userAPIs.getArchivedOrders();
    const archivedOrders = response.message || [];
    const projectUuids = uniqueArrayBase(archivedOrders.map((i) => i.projectUuid));
    const projectResponses = await Promise.all(projectUuids.map((id) => projectsAPIs.getProjects(`uuid=${id}`)));
    let projects = [];
    projectResponses.forEach((i) => {
      projects = projects.concat(i.message || []);
    });
    dispatch({ type: constants.AUTH__SET_ARCHIVED_ORDERS, archivedOrders });
    return { archivedOrders, projects };
  } catch (error) {
    dispatch({ type: constants.AUTH__SET_ARCHIVED_ORDERS, archivedOrders: [] });
    return [];
  }
};

export const joinOrLeaveProgram = async (projectUuid, programUuid) => {
  const transMessages = getTransMessages();
  try {
    const user = getCurrentState().auth.user;
    const joinedProgramUUIDs = user?.projectSettings?.[projectUuid]?.joinedProgramUUIDs || [];
    const isJoined = joinedProgramUUIDs.includes(programUuid);

    const response = isJoined
      ? await projectsAPIs.leaveProgram(projectUuid, programUuid)
      : await projectsAPIs.joinProgram(projectUuid, programUuid);

    const newData = response.message || {};
    dispatch({ type: constants.AUTH__UPDATE_USER, user: newData });
    createNotification(isJoined ? transMessages.LEAVE_SUCCESS : transMessages.SUCCESSFUL_JOIN);
  } catch (error) {
    console.log(error);
    createErrorNotification(transMessages.ERROR);
  }
};
