import { Warning } from '@mui/icons-material';
import { Button, Container, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { MESSAGES } from 'src/utils/constant';
import classes from './alert-popup.module.sass';

const LIMIT_POPUP_TIME = 30 * 1000; // 30s

function AlertPopup({ time = LIMIT_POPUP_TIME, setShouldAlert, callback = () => {} }) {
  const lang = useSelector((state) => state.app.lang);
  const [seconds, setSeconds] = useState(time / 1000);
  let timeout;

  useEffect(() => {
    timeout = setTimeout(() => {
      setShouldAlert(null);
      callback();
    }, time);
    const interval = setInterval(() => {
      setSeconds((old) => (old > 0 ? old - 1 : 0));
    }, 1000);
    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, []);

  return (
    <Stack className={classes.root}>
      <Container sx={{ height: '100%' }}>
        <Stack
          justifyContent="center"
          alignItems="center"
          gap="16px"
          sx={{ height: '100%', width: { xs: '100%', md: '90%' }, margin: 'auto', textAlign: 'center' }}
        >
          <Warning color="warning" sx={{ fontSize: '48px' }} />
          <Typography variant="h4" fontWeight={700}>
            {MESSAGES[lang].ALERT_CONTINUE_FILL_OUT_THE_PAGE?.replace('{second}', seconds)}
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            sx={{ minWidth: '100px' }}
            onClick={() => {
              clearTimeout(timeout);
              setShouldAlert(null);
            }}
          >
            Ok
          </Button>
        </Stack>
      </Container>
    </Stack>
  );
}

export default AlertPopup;
