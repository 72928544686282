import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import { store } from './store';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { QueryClient, QueryClientProvider } from 'react-query';
import ThemeComponent from './theme/ThemeComponent';
import { CaptureConsole } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import ErrorBoundaryWrapper from './components/error-boundary';
import Audio from './components/audio';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 30000, // cache data for 30 seconds before refetching
      retry: false,
    },
  },
});

if (process.env.NODE_ENV === 'production') {
  // replace console.* for disable log on production
  console.log = () => {};

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new CaptureConsole({
        levels: [process.env.REACT_APP_LOG_LEVEL],
      }),
    ],
  });
}

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <BrowserRouter forceRefresh={true}>
          <ThemeComponent>
            <ErrorBoundaryWrapper>
              <Audio />
              <App />
              <ToastContainer />
            </ErrorBoundaryWrapper>
          </ThemeComponent>
        </BrowserRouter>
      </Provider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
