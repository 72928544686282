import { Check, Warning } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { userAPIs } from 'src/services';
import { getCurrentUserProfile } from 'src/store/actions/auth';
import { MESSAGES, TEXT } from 'src/utils/constant';
import { createErrorNotification, createNotification } from 'src/utils/notifications';

function VerifyButton() {
  const user = useSelector((state) => state.auth.user);
  const lang = useSelector((state) => state.app.lang);

  const [isSentCode, setIsSentCode] = useState(false);
  const [verifyCode, setVerifyCode] = useState('');
  const [openInputCode, setOpenInputCode] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);

  const handleVerifyEmail = async () => {
    try {
      await userAPIs.sendVerifyCode();
      setIsSentCode(true);
      createNotification(MESSAGES[lang].MESSAGE_SENT_CODE);
    } catch (error) {
      console.error(error);
      createErrorNotification(MESSAGES[lang].ERROR_CHECK_LATER);
    }
  };

  const handleOpenInputCode = () => {
    setOpenInputCode(true);
    setVerifyCode('');
  };

  const handleCloseInputCode = () => {
    setOpenInputCode(false);
    setVerifyCode('');
  };

  const handleVerifyCode = async () => {
    if (isVerifying) return;
    try {
      setIsVerifying(true);
      await userAPIs.verifyCode(verifyCode);
      createNotification(MESSAGES[lang].VERIFY_SUCCESSFULLY, 'success');
    } catch (error) {
      console.error(error);
      createErrorNotification(MESSAGES[lang].VERIFY_CODE_IS_INVALID_OR_EXPRIED);
    } finally {
      setIsVerifying(false);
      handleCloseInputCode();
      getCurrentUserProfile();
    }
  };

  return (
    user.status === 0 && (
      <Stack direction="row" gap="4px" paddingY="0.5rem" flexWrap="wrap" justifyContent="flex-end">
        {isSentCode && (
          <Button
            variant="outlined"
            sx={{
              textTransform: 'none',
              background: 'white',
              borderRadius: '4px',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
            onClick={handleOpenInputCode}
          >
            {TEXT[lang].INPUT_CODE}
          </Button>
        )}
        <Button
          variant="outlined"
          color={isSentCode ? 'success' : 'warning'}
          startIcon={isSentCode ? <Check /> : <Warning />}
          sx={{
            textTransform: 'none',
            background: 'white',
            borderRadius: '4px',
            '&:hover': {
              textDecoration: 'underline',
            },
          }}
          onClick={handleVerifyEmail}
        >
          {isSentCode ? TEXT[lang].RESEND_EMAIL : TEXT[lang].VERIFY_EMAIL}
        </Button>

        <Dialog open={openInputCode} onClose={handleCloseInputCode}>
          <DialogTitle>{TEXT[lang].INPUT_CODE}</DialogTitle>
          <DialogContent>
            <TextField
              fullWidth
              label={TEXT[lang].CODE}
              variant="standard"
              InputLabelProps={{ shrink: true }}
              value={verifyCode}
              sx={{ minWidth: '300px' }}
              onChange={(e) => setVerifyCode(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button variant="contained" disabled={!verifyCode || isVerifying} onClick={handleVerifyCode}>
              {TEXT[lang].OK}
            </Button>
            <Button variant="outlined" onClick={handleCloseInputCode}>
              {TEXT[lang].CANCEL}
            </Button>
          </DialogActions>
        </Dialog>
      </Stack>
    )
  );
}

export default VerifyButton;
