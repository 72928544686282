import { Button, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { ExitAppIcon } from '../../icons';
import { logout } from '../../store/actions/auth';
import { getFullName, getTransText } from '../../utils/common';
import { EVENTS } from '../../utils/constant';
import eventBus from '../../utils/eventBus';
import { MyAvatar } from '../avatar';
import classes from './main-profile.module.sass';

export function MainProfile({
  topElement = <></>,
  hideUploadAvatar,
  hideLogout,
  showChangePassword,
  extraRightElement = <></>,
}) {
  const user = useSelector((state) => state.auth.user);
  const transText = getTransText();
  const handleLogout = () => logout();

  const handleChangePassword = () => {
    eventBus.emit(EVENTS.OPEN_CHANGE_PASSWORD_FORM);
  };

  return (
    <div className={classes.root}>
      <MyAvatar
        user={user}
        hideUploadAvatar={hideUploadAvatar}
        sx={{ width: '6.25rem', height: '6.25rem', fontSize: '2rem !important' }}
      />

      <div className={classes.mainSection}>
        {topElement}
        <Typography className={classes.name}>{getFullName(user)}</Typography>
        <Typography className={classes.email}>{user.email}</Typography>
        {/* {!hideLogout && (
          <Button
            startIcon={<ExitAppIcon />}
            sx={{
              marginTop: '0.25rem',
              width: 'fit-content',
              color: '#ADADAF',
              textTransform: 'none',
              fontSize: '15px',
            }}
            onClick={handleLogout}
          >
            {transText.LOGOUT_BUTTON}
          </Button>
        )} */}
        {showChangePassword && (
          <Button
            sx={{
              width: 'fit-content',
              color: '#FFF',
              textTransform: 'none',
              fontSize: '12px',
              background: '#BC95F1',
              '&:hover': {
                background: '#b976ff',
              },
            }}
            variant="contained"
            onClick={handleChangePassword}
          >
            {transText.CHANGE_PASSWORD}
          </Button>
        )}
      </div>

      {extraRightElement}
    </div>
  );
}
