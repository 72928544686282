import { Add, ChevronLeft } from '@mui/icons-material';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { Button, CardContent, Grid, IconButton, Stack, Typography } from '@mui/material';
import queryString from 'query-string';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLanguage } from 'src/hooks';
import { routes } from 'src/utils/routes';
import { MainUserProfile } from '../../components';
import { AnketaForm } from '../../components/anketa';
import Loader from '../../components/loader';
import { generalAPIs, userAPIs } from '../../services';
import { getMembersByProject, updateMember } from '../../store/actions/app';
import { EVENTS, MESSAGES, TEXT } from '../../utils/constant';
import eventBus from '../../utils/eventBus';
import classes from './accreadition.module.sass';
import AddNewMember from './AddNewMember';
import MembersTable from './MembersTable';

const AccreaditionPage = ({ projectUuid }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { lang, localizeText } = useLanguage();

  const user = useSelector((state) => state.auth.user);
  const roles = useSelector((state) => state.app.roles);
  const members = useSelector((state) => state.app.members);

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMember, setIsLoadingMember] = useState(false);
  const [showAddNew, setShowAddNew] = useState(false);
  const [membersTotal, setMembersTotal] = useState(0);
  const [userInvitations, setUserInvitations] = useState({});
  const [autoFillTemplate, setAutoFillTemplate] = useState({});

  const rolesById = useMemo(() => {
    return roles?.reduce((p, c) => ({ ...p, [c.id]: c }), {});
  }, [roles]);
  const { showAllMembers, queryParams } = useMemo(() => {
    const queryParams = queryString.parse(location.search);
    return { showAllMembers: queryString.parse(location.search)?.tab === 'members', queryParams };
  }, [location]);

  const handleReturnProject = () => {
    navigate(routes.projectDetail.path.replace(':projectUuid', projectUuid));
  };

  const fetchUserInvitations = async (projectUuid) => {
    try {
      const response = await userAPIs.getUserInvitations(projectUuid);
      setUserInvitations(response.message);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchAutoFillFields = async () => {
    try {
      const response = await generalAPIs.getAutoFillFields();
      setAutoFillTemplate(response.message);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([fetchUserInvitations(projectUuid), fetchAutoFillFields()]);
      setIsLoading(false);
    };

    if (projectUuid) {
      fetchData();
    }
  }, [projectUuid]);

  const {
    isLoading: isLoadingMembers,
    isFetching: isFetchingMembers,
    refetch: refetchMembers,
  } = useQuery(
    ['getMembersByProject', projectUuid, queryParams],
    async ({ queryKey }) => {
      const [, projectUuid, queryParams] = queryKey;
      const { page: queryPage, limit, query: searchText } = queryParams;
      const rowsPerPage = parseInt(limit) || 20;
      const page = Math.max(queryPage || 1, 1);
      const { total } = await getMembersByProject({
        projectUuid,
        limit: rowsPerPage,
        offset: page - 1,
        fullName: searchText,
        // memberUuid: showAllMembers ? undefined : user?.uuid,
      });
      setMembersTotal(total);
    },
    { enabled: !!projectUuid, staleTime: 0 }
  );

  const isLoadingMembersOfProject = isLoadingMembers || isFetchingMembers;

  useEffect(() => {
    if (isLoadingMember) {
      setTimeout(() => {
        setIsLoadingMember(false);
      }, [200]);
    }
  }, [isLoadingMember]);

  const getMemberInfo = useCallback(
    (selectedMember) => {
      const selectedMemberProjectSettings = selectedMember?.projectSettings?.[projectUuid];
      const selectedMemberRole = rolesById[selectedMemberProjectSettings?.mainRoleId];
      const isLocked = selectedMember?.projectSettings?.[projectUuid]?.isLocked;
      const isAccredited = selectedMember?.projectSettings?.[projectUuid]?.isAccredited;
      const readyToAccreditate = selectedMember?.projectSettings?.[projectUuid]?.readyToAccreditate;

      return {
        member: selectedMember,
        role: selectedMemberRole,
        isLocked,
        isAccredited,
        isAccreditedBy: selectedMember?.isAccreditedBy !== '',
        readyToAccreditate,
      };
    },
    [members, rolesById, projectUuid]
  );

  const { member, role, isLocked, isAccredited, isAccreditedBy, readyToAccreditate } = useMemo(() => {
    const selectedMember = { ...user, ...members.find((m) => m.uuid === user?.uuid) };
    return getMemberInfo(selectedMember);
  }, [members, user]);

  const hasInvitations = Object.keys(userInvitations).length > 0;
  const ableAddMember = hasInvitations;

  const handleForceEdit = (memberUuid) => {
    eventBus.emit(EVENTS.OPEN_CONFIRM_MODAL, {
      title: TEXT[lang].CONFIRM,
      content: `${MESSAGES[lang].FORCE_EDIT_CONFIRM}`,
      onOk: async () => {
        await updateMember(memberUuid, {
          updatingUserFields: { forceEdit: true, currentProjectUuid: projectUuid },
        });
        await refetchMembersOfProject();
      },
    });
  };

  const refetchMembersOfProject = async () => {
    setIsLoadingMember(true);
    await Promise.all([refetchMembers(), fetchUserInvitations(projectUuid)]);
  };

  return (
    <div className={classes.content}>
      <div className={classes.header}>
        <IconButton
          onClick={() =>
            showAllMembers
              ? navigate(routes.projectAccreadition.path.replace(':projectUuid', projectUuid))
              : handleReturnProject()
          }
        >
          <ChevronLeft />
        </IconButton>
        <h2 className={classes.title}>{showAllMembers ? localizeText.MEMBERS : TEXT[lang].ACCREDITATION}:</h2>
      </div>

      {showAllMembers ? (
        <MembersTable
          projectUuid={projectUuid}
          rolesById={rolesById}
          members={members.filter((m) => m.uuid !== user?.uuid)}
          total={membersTotal}
          isLoadingMembers={isLoadingMembersOfProject}
          autoFillTemplate={autoFillTemplate}
          userInvitations={userInvitations}
          fetchUserInvitations={fetchUserInvitations}
          refetchMembersOfProject={refetchMembersOfProject}
        />
      ) : (
        <>
          {isLoading || isLoadingMembersOfProject ? (
            <Loader />
          ) : showAddNew ? (
            <AddNewMember
              projectUuid={projectUuid}
              rolesById={rolesById}
              setShowAddNew={setShowAddNew}
              userInvitations={userInvitations}
              fetchUserInvitations={fetchUserInvitations}
              refetchMembersOfProject={refetchMembersOfProject}
            />
          ) : (
            <>
              {!isLoadingMember ? (
                <>
                  <Grid container spacing={8}>
                    <Grid item xs={12} md={3}>
                      <Stack className={classes.userSection} gap="16px" alignItems="center">
                        <MainUserProfile user={member} role={role} projectUuid={projectUuid} />
                        <Typography
                          sx={{ cursor: 'pointer', textTransform: 'none', textDecoration: 'underline' }}
                          onClick={() => navigate(location.pathname + '?tab=members')}
                        >
                          {localizeText.SHOW_MEMBERS}
                        </Typography>
                        {hasInvitations && (
                          <Button
                            variant="contained"
                            color="secondary"
                            endIcon={<Add />}
                            onClick={() => setShowAddNew(true)}
                          >
                            {localizeText.ADD_MEMBER}
                          </Button>
                        )}
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <Stack>
                        <Stack direction="row" justifyContent="flex-end" gap="8px">
                          {isLocked && (
                            <IconButton
                              color="primary"
                              className={classes.editBtn}
                              onClick={() => handleForceEdit(member?.uuid)}
                            >
                              <ModeEditIcon />
                            </IconButton>
                          )}
                        </Stack>

                        {!isLoadingMember && (
                          <AnketaForm
                            user={member}
                            isLocked={isLocked}
                            projectUuid={projectUuid}
                            ableAddMember={ableAddMember}
                            // quota={currentUserQuota}
                            isAccreditedBy={isAccreditedBy}
                            hasInvitations={hasInvitations}
                            onAddNewAccreadition={() => setShowAddNew(true)}
                            onReloadMembers={refetchMembersOfProject}
                            autoFillTemplate={autoFillTemplate}
                          />
                        )}
                      </Stack>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <Loader />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default memo(AccreaditionPage, (prevProps, nextProps) => prevProps.projectUuid === nextProps.projectUuid);
