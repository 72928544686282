export function ExitAppIcon({ color = '#ADADAF' }) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.1222 13.9889L10.5556 15.5556L5 10L10.5556 4.44444L12.1222 6.01111L9.25556 8.88889H20V11.1111H9.25556L12.1222 13.9889ZM2.22222 0H17.7778C19.0111 0 20 1 20 2.22222V6.66667H17.7778V2.22222H2.22222V17.7778H17.7778V13.3333H20V17.7778C20 19 19.0111 20 17.7778 20H2.22222C1 20 0 19 0 17.7778V2.22222C0 1 1 0 2.22222 0Z"
        fill={color}
      />
    </svg>
  );
}
