import { ChevronLeft } from '@mui/icons-material';
import { Button, Card, CardContent, CircularProgress, Grid, IconButton, Stack, Typography } from '@mui/material';
import moment from 'moment-timezone';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from 'src/components/loader';
import { routes } from 'src/utils/routes';
import { ConfirmModal, MainLayout, PackageDetailModal, UserProject, ViewImageModal } from '../../components';
import AmountToggle from '../../components/amount-toggle';
import { AddShoppingIcon } from '../../icons';
import {
  addToOrder,
  countOrder,
  getProjectPackages,
  getProjectsList,
  joinProject,
  removeFromOrder,
} from '../../store/actions/app';
import { EVENTS, LOCALIZATION, MESSAGES, TEXT } from '../../utils/constant';
import eventBus from '../../utils/eventBus';
import classes from './buy-ticket.module.sass';

const BuyTicketPage = () => {
  const navigate = useNavigate();
  const lang = useSelector((state) => state.app.lang) || LOCALIZATION.ru_RU;
  const transText = TEXT[lang];
  const order = useSelector((state) => state.app.order);
  const user = useSelector((state) => state.auth.user);

  const { projectUuid: id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [packages, setPackages] = useState([]);

  const isJoinedProject = user?.projectUuids?.includes(id);

  const handleReturnProject = () => {
    navigate(`/projects/${id}`);
  };

  const handleAddToOrder = (p) => {
    document.getElementById('shopping-cart')?.classList?.add('shake');
    addToOrder(id, p, () => {
      navigate(routes.orderDetail.path.replace(':projectUuid', id));
    });
    setTimeout(() => {
      document.getElementById('shopping-cart')?.classList?.remove('shake');
    }, 5000);
  };

  const handleClickAddPackage = (p) => {
    if (!isJoinedProject) {
      eventBus.emit(EVENTS.OPEN_CONFIRM_MODAL, {
        title: TEXT[lang].CONFIRM,
        content: TEXT[lang].PARTICIPATE_PROJECT,
        onOk: () => {
          joinProject(id);
          handleAddToOrder(p);
        },
      });
    } else {
      handleAddToOrder(p);
    }
  };

  const handleRemoveFromOrder = (p) => {
    document.getElementById('shopping-cart')?.classList?.add('shake');
    removeFromOrder(id, p);
    setTimeout(() => {
      document.getElementById('shopping-cart')?.classList?.remove('shake');
    }, 5000);
  };

  const handleCount = (p, count) => {
    document.getElementById('shopping-cart')?.classList?.add('shake');
    countOrder(id, p, count);
    setTimeout(() => {
      document.getElementById('shopping-cart')?.classList?.remove('shake');
    }, 5000);
  };

  useEffect(() => {
    const fetchData = async () => {
      const packages = await getProjectPackages(
        id,
        queryString.stringify({
          countryId: 250,
          since: 1,
          till: 1726428683462,
          limit: 10,
          offset: 0,
        })
      );
      setPackages(packages);
      setIsLoading(false);
    };

    fetchData();
  }, [id]);

  const getAmount = (_package) => {
    return (
      order.find((item) => item.projectUuid === id)?.packages.find((_p) => _p.package.id === _package.id)?.count || 0
    );
  };

  const handleShowDetail = (p) => {
    eventBus.emit(EVENTS.OPEN_PACKAGE_DETAIL_MODAL, {
      projectUuid: id,
      packages: [p],
    });
  };

  return (
    <div className={classes.content}>
      <div className={classes.header}>
        <IconButton onClick={handleReturnProject}>
          <ChevronLeft />
        </IconButton>
        <h2 className={classes.title}>{transText.PURCHASE_SERVICE_PACKAGE}</h2>
      </div>
      <Grid container spacing={2} className={classes.optionList}>
        {isLoading && <Loader />}
        {packages.map((p, i) => {
          const name = p?.localization?.[lang]?.name?.trim();

          return (
            <Grid key={i} item xs={12} md={6}>
              <div className={classes.option}>
                <div className={classes.title}>
                  « {name && !name.includes('null') ? name : p.localization?.[LOCALIZATION.ru_RU]?.name.trim()} »
                </div>
                <Grid container spacing={2}>
                  {p.services.map((service, _i) => {
                    const title = service.localization?.[lang]?.title;
                    return (
                      <Grid key={_i} item xs={12} md={6}>
                        <span className={classes.service}>
                          {title && !title.includes('null') ? title : service.localization?.[LOCALIZATION.ru_RU]?.title}
                        </span>
                      </Grid>
                    );
                  })}
                </Grid>
                <Typography
                  textAlign="center"
                  color="#BC95F1"
                  sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}
                  onClick={() => handleShowDetail(p)}
                >
                  {transText.SHOW_PACKAGE_DETAILS}
                </Typography>
                {getAmount(p) > 0 && (
                  <AmountToggle
                    amount={getAmount(p)}
                    onAdd={() => handleClickAddPackage(p)}
                    onDistract={() => handleRemoveFromOrder(p)}
                    handleCount={(count) => handleCount(p, count)}
                  />
                )}
                <Button
                  variant="contained"
                  endIcon={<AddShoppingIcon />}
                  className={classes.button}
                  onClick={() => handleClickAddPackage(p)}
                >
                  <NumberFormat
                    value={p.price?.['643']?.price}
                    suffix=" руб"
                    decimalScale={2}
                    displayType="text"
                    thousandSeparator=" "
                    decimalSeparator=","
                  />
                </Button>
              </div>
            </Grid>
          );
        })}
      </Grid>
      {!isLoading && packages.length === 0 && (
        <Typography variant="h6" color="text.secondary" sx={{ margin: '0 24px', textAlign: 'center' }}>
          {MESSAGES[lang].NO_DATA}
        </Typography>
      )}
    </div>
  );
};

export default BuyTicketPage;
