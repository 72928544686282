import { Box, Button, Divider, IconButton, InputAdornment, Link, Stack, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { AuthLayout, ForgotPasswordForm } from '../../components';
import classes from './login.module.sass';
import queryString from 'query-string';

import { yupResolver } from '@hookform/resolvers/yup';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useForm } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router-dom';
import { authAPIs } from '../../services';
import { login, updateIsNewAccount } from '../../store/actions/auth';
import { loginValidationSchema, loginWithoutPasswordValidationSchema } from '../../utils/formValidator';
import { createNotification } from '../../utils/notifications';
import { useSelector } from 'react-redux';
import { LOCALIZATION, MESSAGES, TEXT } from '../../utils/constant';
import YandexIcon from '../../icons/yandex';
import { routes } from 'src/utils/routes';

const LoginPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCheckedEmail, setIsCheckedEmail] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [showLockLogin, setShowLockLogin] = useState(false);
  const lang = useSelector((state) => state.app.lang) || LOCALIZATION.ru_RU;

  const { projectUuid, roleUuid } = queryString.parse(location.search);

  const {
    register,
    handleSubmit,
    unregister,
    formState: { errors },
  } = useForm({
    defaultValues: { email: '', password: '' },
    resolver: yupResolver(isCheckedEmail ? loginValidationSchema(lang) : loginWithoutPasswordValidationSchema(lang)),
  });

  const onSubmit = async ({ email, password }) => {
    console.log('email');
    console.log(email);
    try {
      setIsSubmitting(true);
      setShowWarning(false);
      setShowLockLogin(false);

      console.log('isCheckedEmail');
      console.log(isCheckedEmail);

      if (!isCheckedEmail) {
        try {
          console.log('checkEmail');
          await authAPIs.checkEmail({ email });
          setIsCheckedEmail(true);
        } catch (e) {
          console.log(e);
          navigate(routes.register.path + location.search, { state: { email } });
        }
      }
      if (email && password) {
        const res = await login({ email, password, projectUuid, roleUuid }, true);
        if (res && projectUuid) {
          updateIsNewAccount({ isNewAccount: true, newProjectUuid: projectUuid });
        }
        if (res?.code === 400) setShowWarning(true);
        if (res?.code === 429) setShowLockLogin(true);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleLoginWithYandex = async () => {
    if (isSubmitting) return;
    try {
      setIsSubmitting(true);
      window.open(`${process.env.REACT_APP_SERVER_URL}/auth/yandex`, '_self');
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    unregister('password');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location.state && location.state.message) {
      createNotification(location.state.message);
    }
  }, [location]);

  return (
    <AuthLayout>
      <div className={classes.root}>
        <span className={classes.title} color="initial">
          {TEXT[lang].AUTHORIZATION}
        </span>
        <span className={classes.description} color="initial">
          {TEXT[lang].REG_ON_EVENT}
        </span>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <TextField
            label="E-mail"
            InputLabelProps={{
              shrink: true,
            }}
            autoFocus
            variant="standard"
            {...register('email')}
            error={errors.email ? true : false}
            helperText={errors.email?.message}
          />

          <Stack>
            {isCheckedEmail && (
              <TextField
                label={TEXT[lang].PASSWORD}
                type={showPassword ? 'text' : 'password'}
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                variant="standard"
                {...register('password')}
                error={errors.password ? true : false}
                helperText={errors.password?.message}
              />
            )}
            <Typography
              sx={{
                color: (theme) =>
                  showWarning ? theme.palette.warning.main : showLockLogin ? theme.palette.error.main : '',
              }}
            >
              {showWarning ? MESSAGES[lang].LOGIN_WARNING : showLockLogin ? MESSAGES[lang].LOGIN_ERROR : ''}
            </Typography>
          </Stack>

          <Link href="/forgot-password" underline="none" style={{ marginTop: '-0.75rem', color: 'black' }}>
            {TEXT[lang].FORGOT_PASSWORD} ?
          </Link>

          <Button
            type="submit"
            variant="contained"
            size="large"
            className={classes.submitButton}
            disabled={isSubmitting || showLockLogin}
          >
            {TEXT[lang].READY}
          </Button>

          {/* <Link href="/register" underline="none" color="#000" style={{ textAlign: 'center', fontSize: '1.25rem' }}>
            У меня ещё нет аккаунта
          </Link> */}

          <Box className={classes.loginOr}>
            <hr />
            <span>{TEXT[lang].OR}</span>
          </Box>
          <Stack>
            <Button
              startIcon={<YandexIcon />}
              className={classes.loginYandex}
              sx={{
                '& .MuiButton-startIcon, & svg': {
                  width: '24px',
                  height: '24px',
                },
              }}
              onClick={handleLoginWithYandex}
            >
              {MESSAGES[lang].LOGIN_WITH_YANDEX_ID}
            </Button>
          </Stack>
        </form>

        <ForgotPasswordForm />
      </div>
    </AuthLayout>
  );
};

export default LoginPage;
