import React from 'react';

export function DashboardIcon({ color = '#FFF' }) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 9.2C1.33726 9.2 0.8 8.66274 0.8 8V2C0.8 1.33726 1.33726 0.8 2 0.8H6C6.66274 0.8 7.2 1.33726 7.2 2V8C7.2 8.66274 6.66274 9.2 6 9.2H2ZM2 17.2C1.33726 17.2 0.8 16.6627 0.8 16V14C0.8 13.3373 1.33726 12.8 2 12.8H6C6.66274 12.8 7.2 13.3373 7.2 14V16C7.2 16.6627 6.66274 17.2 6 17.2H2ZM12 17.2C11.3373 17.2 10.8 16.6627 10.8 16V10C10.8 9.33726 11.3373 8.8 12 8.8H16C16.6627 8.8 17.2 9.33726 17.2 10V16C17.2 16.6627 16.6627 17.2 16 17.2H12ZM10.8 2C10.8 1.33726 11.3373 0.8 12 0.8H16C16.6627 0.8 17.2 1.33726 17.2 2V4C17.2 4.66274 16.6627 5.2 16 5.2H12C11.3373 5.2 10.8 4.66274 10.8 4V2Z"
        stroke={color}
        strokeWidth="1.6"
      />
    </svg>
  );
}
