import { ModeEdit } from '@mui/icons-material';
import { Grid, IconButton, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AnketaForm } from 'src/components/anketa';
import Loader from 'src/components/loader';
import { generalAPIs, userAPIs } from 'src/services';
import { getMembersByProject, updateMember } from 'src/store/actions/app';
import { EVENTS, MESSAGES, TEXT } from 'src/utils/constant';
import eventBus from 'src/utils/eventBus';
import StepFooter from './StepFooter';

function StepThree({
  selectedProject,
  activeStep,
  steps,
  checkAbleNext,
  refetchAnketaUserData = () => {},
  handleNext = () => {},
  handlePrev = () => {},
  handleError = () => {},
}) {
  const currentUser = useSelector((state) => state.auth.user);
  const members = useSelector((state) => state.app.members);
  const lang = useSelector((state) => state.app.lang);

  const [isLoading, setIsLoading] = useState(true);
  const [userInvitations, setUserInvitations] = useState({});
  // const [autoFillTemplate, setAutoFillTemplate] = useState({});
  const [isLoadingMember, setIsLoadingMember] = useState(false);

  const user = members?.find((member) => member.uuid === currentUser?.uuid);
  const quota = user?.projectSettings?.[selectedProject]?.quota || 0;
  // const ableAddMember = members.length - 1 < quota;
  const isLocked = user?.projectSettings?.[selectedProject]?.isLocked;
  const ableAddMember = false;
  const isAccreditedBy = user?.accreditedBy !== '';
  // const hasInvitations = Object.keys(userInvitations).length > 0;
  const hasInvitations = false;

  // const fetchAutoFillFields = async () => {
  //   try {
  //     const response = await generalAPIs.getAutoFillFields();
  //     setAutoFillTemplate(response.message);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const fetchUserInvitations = async (projectUuid) => {
    try {
      const response = await userAPIs.getUserInvitations(projectUuid);
      setUserInvitations(response.message);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchData = async () => {
    await Promise.all([
      // fetchAutoFillFields(),
      fetchUserInvitations(selectedProject),
      getMembersByProject({ projectUuid: selectedProject }),
    ]);
    setIsLoading(false);
  };

  useEffect(() => {
    if (activeStep === steps.length - 2) {
      fetchData();
    }
  }, [activeStep, selectedProject]);

  useEffect(() => {
    if (isLoadingMember) {
      setTimeout(() => {
        setIsLoadingMember(false);
      }, [200]);
    }
  }, [isLoadingMember]);

  const refetchMembersOfProject = async () => {
    setIsLoadingMember(true);
    refetchAnketaUserData();
    await getMembersByProject({ projectUuid: selectedProject });
    fetchUserInvitations(selectedProject);
  };

  const handleForceEdit = () => {
    eventBus.emit(EVENTS.OPEN_CONFIRM_MODAL, {
      title: TEXT[lang].CONFIRM,
      content: `${MESSAGES[lang].FORCE_EDIT_CONFIRM}`,
      onOk: async () => {
        await updateMember(user?.uuid, {
          updatingUserFields: { forceEdit: true, currentProjectUuid: selectedProject },
        });
        refetchMembersOfProject();
      },
    });
  };

  return (
    <>
      <Stack gap="8px" sx={{ height: '100%' }}>
        {isLoading || isLoadingMember ? (
          <Loader />
        ) : (
          <>
            <Stack alignItems="flex-end">
              {isLocked && (
                <IconButton
                  color="primary"
                  sx={{ borderRadius: '4px', width: 'fit-content', border: '1px solid' }}
                  onClick={handleForceEdit}
                >
                  <ModeEdit />
                </IconButton>
              )}
            </Stack>
            <AnketaForm
              user={user}
              isLocked={isLocked}
              projectUuid={selectedProject}
              ableAddMember={ableAddMember}
              quota={quota}
              isAccreditedBy={isAccreditedBy}
              hasInvitations={hasInvitations}
              onAddNewAccreadition={() => {}}
              onReloadMembers={refetchMembersOfProject}
              autoFillTemplate={{}}
              preventAutoFill={true}
              sx={{ height: '100%', width: '70%', justifyContent: 'space-between' }}
              formSx={{ '& *': { fontSize: '18px' } }}
              hiddenFields={['email', 'telephone']}
              controlElements={(onSubmit, isSubmitting) => (
                <StepFooter
                  activeStep={activeStep}
                  steps={steps}
                  isSubmitting={isSubmitting}
                  // checkAbleNext={checkAbleNext}
                  handlePrev={handlePrev}
                  handleNext={() => onSubmit()}
                />
              )}
              callbackSubmit={() => handleNext()}
            />
          </>
        )}
      </Stack>
      {/* <StepFooter
        activeStep={activeStep}
        steps={steps}
        checkAbleNext={checkAbleNext}
        handlePrev={handlePrev}
        handleNext={handleNext}
      /> */}
    </>
  );
}

export default StepThree;
