import { Chip } from '@mui/material';
import { memo } from 'react';
import { useLanguage } from 'src/hooks';
import { LOCALIZATION } from 'src/utils/constant';

function UserRole({ role, sx }) {
  const { lang } = useLanguage();

  if (!role) return null;
  return <Chip sx={{ ...sx }} label={role?.name?.[lang] || role?.name?.[LOCALIZATION.ru_RU]} color="success" />;
}

export default memo(UserRole);
