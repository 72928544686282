import { Article, Chat, ExpandLess, ExpandMore } from '@mui/icons-material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Collapse, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useCurrentPath } from 'src/hooks';
import PersonalLineIcon from 'src/icons/personal-line';
import { userAPIs } from 'src/services';
import { createErrorNotification } from 'src/utils/notifications';
import { routes } from 'src/utils/routes';
import { DashboardIcon, DashboardIcon2 } from '../../../../icons';
import { getUserProjects } from '../../../../store/actions/auth';
import { LOCALIZATION, MESSAGES, TEXT } from '../../../../utils/constant';
import classes from './left-menu.module.sass';

export function LeftMenu() {
  const navigate = useNavigate();
  const [open, setOpen] = useState({});
  const user = useSelector((state) => state.auth.user);
  const userProjects = useSelector((state) => state.auth.projects);
  const projects = useSelector((state) => state.app.projects);
  const order = useSelector((state) => state.app.order);
  const lang = useSelector((state) => state.app.lang) || LOCALIZATION.ru_RU;

  const { path: basePath } = useCurrentPath();
  const isHomePage = basePath === routes.homepage.path;
  const joinedProjects = (projects || []).filter((p) => user?.projectUuids?.includes(p.uuid));
  const BDKjoinedProjects = (joinedProjects || []).filter(
    (p) =>
      (p.modules.BDK && p.uuid !== 'faaa8088-f7be-780b-d8d5-56bd061c429c') ||
      (p.modules.BDK &&
        p.uuid === 'faaa8088-f7be-780b-d8d5-56bd061c429c' &&
        user?.projectSettings?.[p.uuid]?.mainRoleId === 'e44095a7-0af4-f25b-35f8-eb160f00e036')
  );
  // const BDKjoinedProjects = (joinedProjects || []).filter(
  //   (p) => (p.modules.BDK)
  // );

  useEffect(() => {
    getUserProjects();
  }, []);

  const handleClick = (e) => setOpen({ [e]: !open[e] });

  const menu = [
    {
      id: 1,
      name: isHomePage ? TEXT[lang].ALL_PROJECTS : TEXT[lang].LEFT_MENU_PROJECTS,
      icon: <DashboardIcon />,
      href: '/',
      subItems: !isHomePage
        ? null
        : joinedProjects.map((project) => {
            return {
              id: project.uuid,
              name: project.localization?.[lang]?.fullName || project.name,
              href: routes.projectDetail.path.replace(':projectUuid', project.uuid),
              // isJoined: user?.projectUuids?.includes(project.uuid),
            };
          }),
    },

    // {
    //   id: 2,
    //   name: TEXT[lang].BASKET,
    //   icon: <DashboardIcon2 />,
    //   subItems: joinedProjects?.map((project, index) => {
    //     return {
    //       id: index + 1,
    //       name: project.localization?.[lang]?.fullName || project.name,
    //       href: routes.myOrder.path.replace(':projectUuid', project.uuid),
    //     };
    //   }),
    // },
    {
      id: 4,
      name: TEXT[lang].LEFT_MENU_CALENDARS,
      icon: <CalendarMonthIcon sx={{ color: '#FFF' }} />,
      subItems: userProjects?.map((project, index) => {
        return {
          id: index + 1,
          name: project.localization?.[lang]?.fullName || project.name,
          href: `${routes.projectCalendarMenu.path.replace(':projectUuid', project.uuid)}`,
        };
      }),
    },
    // {
    //   id: 6,
    //   name: TEXT[lang].INFORMATION_FILES,
    //   icon: <Article sx={{ color: '#FFF' }} />,
    //   subItems: joinedProjects.map((project) => {
    //     return {
    //       id: project.uuid,
    //       name: project.localization?.[lang]?.fullName || project.name,
    //       href: routes.projectInfoFiles.path.replace(':projectUuid', project.uuid),
    //       // isJoined: user?.projectUuids?.includes(project.uuid),
    //     };
    //   }),
    // },
    // BDKjoinedProjects.length > 0 && {
    //   id: 3,
    //   name: TEXT[lang].BUSINESS_CARD,
    //   icon: <PersonalLineIcon className={classes.icon} />,
    //   subItems: BDKjoinedProjects?.map((project, index) => {
    //     return {
    //       id: index + 1,
    //       name: project.localization?.[lang]?.fullName || project.name,
    //       href: `${routes.projectVizits.path.replace(':projectUuid', project.uuid)}?tab=business-card`,
    //     };
    //   }),
    // },
    BDKjoinedProjects.length > 0 && {
      id: 5,
      name: TEXT[lang].CHAT,
      icon: <Chat sx={{ color: '#FFF' }} />,
      subItems: BDKjoinedProjects?.map((project, index) => {
        return {
          id: index + 1,
          name: project.localization?.[lang]?.fullName || project.name,
          href: routes.projectChat.path.replace(':projectUuid', project.uuid),
          middlewareNavigate: async () => {
            try {
              await userAPIs.getUserVizitsProject(project.uuid);
              return true;
            } catch (error) {
              createErrorNotification(MESSAGES[lang].CREATE_YOUR_BUSINESS_CARD_TO_BUY_A_PACKAGE);
              navigate(routes.projectVizits.path.replace(':projectUuid', project.uuid) + '?tab=business-card');
              return false;
            }
          },
        };
      }),
    },
  ].filter((item) => !!item);

  return (
    <List component="nav" className={classes.root}>
      {menu.map((item, i) =>
        item.subItems != null ? (
          <React.Fragment key={item.id}>
            <ListItem className={classes.item} onClick={() => handleClick(item.name)}>
              <ListItemIcon sx={{ minWidth: '32px !important' }}>{item.icon}</ListItemIcon>
              <ListItemText primary={item.name} primaryTypographyProps={{ sx: { color: 'white' } }} />
              {open[item.name] ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open[item.name]} timeout="auto" unmountOnExit>
              {item.subItems.map((subItem) => (
                <List
                  key={subItem.id}
                  className={classes.subItem}
                  component="div"
                  disablePadding
                  sx={{
                    borderRadius: '4px',
                    '&:hover': {
                      background: 'rgb(240 237 237 / 22%)',
                    },
                  }}
                >
                  <ListItem
                    button
                    sx={{
                      borderRadius: '4px',
                      '&:hover': {
                        background: 'rgb(240 237 237 / 22%)',
                      },
                    }}
                    onClick={async () => {
                      if (typeof subItem.middlewareNavigate === 'function') {
                        const passMiddleware = await subItem.middlewareNavigate();
                        if (!passMiddleware) return;
                      }
                      navigate(subItem.href);
                    }}
                  >
                    <ListItemText inset primary={subItem.name} primaryTypographyProps={{ sx: { color: 'white' } }} />
                  </ListItem>
                  {/* <BadgeDot
                    style={{
                      position: 'absolute',
                      top: '16px',
                      right: '16px',
                      '& .MuiBadge-badge': {
                        background: !subItem.isJoined && '#FFF',
                        color: !subItem.isJoined && '#FFF',
                        boxShadow: 'none',
                      },
                    }}
                  /> */}
                </List>
              ))}
            </Collapse>
          </React.Fragment>
        ) : (
          <ListItem key={item.id} className={classes.item} onClick={() => navigate(item.href)}>
            <ListItemIcon sx={{ minWidth: '32px !important' }}>{item.icon}</ListItemIcon>
            <ListItemText primary={item.name} primaryTypographyProps={{ sx: { color: 'white' } }} />
          </ListItem>
        )
      )}
    </List>
  );
}
